import noop from 'lodash/noop';
import React, { Fragment, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ModalBody, ModalBodyProps, ModalComponentName } from '@components';
import { Button, ButtonProps } from '@elements/button';
import { Dialog, Transition } from '@headlessui/react';
import { closeModalSelector, useModalStore } from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

import { Text, TextProps } from '../text';

export interface ModalProps {
  isOpen?: boolean;
  bodyTextProps?: TextProps;
  title?: string | React.ReactNode;
  titleClassName?: string;
  titleText?: string;
  body?: string | ModalBodyProps<ModalComponentName>;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  bgColor?: 'snow' | 'mint' | 'charcoal';
  ImageTitle?: React.ReactNode;
  Footer?: React.ReactNode;
  showCloseButton?: boolean;
  showButtonsInColumn?: boolean;
  actions?: ButtonProps[];
  blurOverlay?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  onClose?: () => Promise<unknown> | void;
  sendExitEvent?: boolean;
  bodyContainerClassName?: string;
  children?: React.ReactNode;
  shouldDisableAutoFocus?: boolean;
}

export const sizeClassNames = {
  sm: '!w-96',
  md: '!w-[530px]',
  lg: '!w-[768px]',
  xl: '!w-[1024px]',
};

export const bgColorClassNames = {
  snow: 'bg-cabo-50',
  mint: 'bg-nusa-200',
  charcoal: 'bg-fuji-800-100',
};

export const Modal: React.FC<ModalProps> = ({
  bodyTextProps,
  title,
  titleClassName = 'text-center',
  titleText,
  body,
  Footer,
  ImageTitle = null,
  size = 'sm',
  showCloseButton = true,
  showButtonsInColumn = false,
  actions,
  blurOverlay = true,
  shouldCloseOnOverlayClick = true,
  bodyContainerClassName = 'mt-4 p-4 sm:p-6',
  onClose,
  sendExitEvent,
  children,
  bgColor = 'snow',
}) => {
  // TODO: convert all title props to titleText due to i18n children props
  const isTitleTextWithTextIdDisplayed = !title && titleText;
  const isBodyTextDisplayed = !body && bodyTextProps;
  const closeModal = useModalStore(closeModalSelector);
  const initialFocusRef = useRef<HTMLButtonElement>(null);

  const handleClose = async () => {
    await onClose?.();
    if (sendExitEvent) {
      sendAnalyticsEvent('Modal exited');
    }
    closeModal();
  };

  return (
    <Dialog
      initialFocus={initialFocusRef}
      as="div"
      className="relative z-10"
      onClose={shouldCloseOnOverlayClick ? handleClose : noop}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div
          className={twMerge('fixed inset-0', blurOverlay && 'bg-fuji-800/25 backdrop-blur-md ')}
        />
      </Transition.Child>
      <div
        {...testProps('modal-body')}
        className={twMerge('fixed inset-0 z-10', 'overflow-y-auto')}>
        <span ref={initialFocusRef} className="hidden"></span>
        <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel
              className={twMerge(
                sizeClassNames[size],
                'relative w-full transform overflow-hidden rounded-2xl !bg-mono-100 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl',
                bgColorClassNames[bgColor],
              )}>
              {showCloseButton && (
                <div className="absolute top-0 right-0 pt-s2 pr-s2">
                  <Button
                    type="button"
                    onClick={handleClose}
                    variant="tertiary"
                    icon="cancel_filled"
                  />
                </div>
              )}
              {ImageTitle}
              <div className={twMerge(bodyContainerClassName, titleClassName)}>
                {isTitleTextWithTextIdDisplayed && (
                  <Dialog.Title
                    as={Text}
                    variant="h2-36/sb"
                    {...testProps('modal-title-with-text-id')}>
                    <Text variant="h2-36/sb">{titleText}</Text>
                  </Dialog.Title>
                )}
                {React.isValidElement(title) ? title : null}
                {typeof title === 'string' ? (
                  <Dialog.Title as={Text} variant="h2-36/sb" {...testProps('modal-title')}>
                    {title}
                  </Dialog.Title>
                ) : null}
                <div>
                  {isBodyTextDisplayed ? (
                    <Dialog.Description
                      as={Text}
                      variant="body-16/m"
                      {...testProps('modal-description-with-display-text-id')}>
                      <Text
                        variant="body-16/m"
                        withMarkDown
                        {...isBodyTextDisplayed}
                        {...testProps('modal-description')}>
                        {isBodyTextDisplayed.children}
                      </Text>
                    </Dialog.Description>
                  ) : null}
                  {React.isValidElement(body) ? body : null}
                  {typeof body === 'string' ? (
                    <Dialog.Description
                      as={Text}
                      variant="body-16/m"
                      {...testProps('modal-description')}>
                      {body}
                    </Dialog.Description>
                  ) : null}
                  {typeof body === 'object' ? (
                    <ModalBody {...(body as ModalBodyProps<ModalComponentName>)} />
                  ) : null}
                </div>
              </div>
              {Footer
                ? Footer
                : actions && (
                    <div
                      className={twMerge(
                        'mt-5 p-4',
                        !showButtonsInColumn &&
                          'sm:mt-6 sm:flex sm:justify-between sm:space-x-4 sm:p-6',
                        showButtonsInColumn && 'flex-col',
                      )}>
                      {actions.map((buttonProps, index) => (
                        <Button
                          className={twMerge('w-full mb-2 md:mb-0', showButtonsInColumn && 'mt-3')}
                          key={index}
                          {...buttonProps}
                          onClick={async event => {
                            await buttonProps?.onClick?.(event);
                            closeModal();
                          }}
                        />
                      ))}
                    </div>
                  )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
      {children}
    </Dialog>
  );
};
