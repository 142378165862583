/* boostCoverItems Component */

/* Create UI based on the boost details API response */
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { BoostCoverItem } from '@packages/types';

import { Text } from '../text';
import { BulletPointsItem } from './bulletPointsItem.component';
import { SheetTableList } from './sheetTableList.component';
import { TableItem } from './tableItem.component';

export interface BoostCoverItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  isCovered?: boolean;
  boostCoverItems?: BoostCoverItem[] | null;
  isInBorderdTab?: boolean | false;
}
export const BoostCoverItems: React.FC<BoostCoverItemsProps> = ({
  boostCoverItems,
  isCovered,
  className,
  isInBorderdTab,
  ...rest
}) => {
  if (!boostCoverItems || boostCoverItems.length === 0) {
    return null;
  }

  //Dividing items into two columns for large screens
  let leftColList: BoostCoverItem[] = [];
  let rightColList: BoostCoverItem[] = [];
  const boostCoverItemListLength = boostCoverItems.length;
  leftColList = boostCoverItems.slice(0, boostCoverItemListLength / 2);
  rightColList = boostCoverItems.slice(boostCoverItemListLength / 2, boostCoverItemListLength);

  //No "bullet" and "text" type as the first item of the right column
  while (rightColList[0].type == 'text' || rightColList[0].type == 'bullet') {
    leftColList.push(rightColList[0]);
    rightColList = rightColList.slice(1);
  }

  const createItems = (list: BoostCoverItem[]) => {
    return list.map((item, index) => {
      const itemType = item.type;
      switch (itemType) {
        case 'bold':
          return (
            <div className="flex-1 pb-3" key={`${itemType}${index}`}>
              <Text variant="subTitle-20/sb">{item.value}</Text>
            </div>
          );
        case 'text':
          return (
            <div className="flex-1 pb-5" key={`${itemType}${index}`}>
              <Text variant="body-16/r">{item.value}</Text>
            </div>
          );
        case 'table':
          return (
            <div className="flex-1 pb-8" key={`${itemType}${index}`}>
              <TableItem item={item} />
            </div>
          );
        case 'bullet':
          return (
            <div className="flex-1 pb-8" key={`${itemType}${index}`}>
              <BulletPointsItem item={item} isCovered={isCovered} />
            </div>
          );
        case 'sheet-table':
          return <SheetTableList key={`${itemType}${index}`} item={item} />;
        default:
          return null;
      }
    });
  };

  const columnClassName = isInBorderdTab ? 'sm:w-full' : 'sm:w-10/12';
  return (
    <div className={twMerge('grid', className)} {...rest}>
      <div className={columnClassName}>{createItems(leftColList)}</div>
      <div className={columnClassName}>{createItems(rightColList)}</div>
    </div>
  );
};
