import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { SelectOption } from '@packages/types';

import { Assets } from '@assets';
import { Combobox as ComboboxReact } from '@headlessui/react';
import { testProps } from '@utils';

import { Input, InputProps } from '../input';
import { Loader } from '../loader';

interface ComboboxProps {
  items: SelectOption[];
  showSelectedLabel?: boolean;
  onSelect: (value: unknown) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  className?: string;
  optionClassName?: string;
  defaultValue?: SelectOption;
  overrideSelectedValue?: SelectOption;
  inputProps?: InputProps;
  type?: 'search' | 'select';
  isLoading?: boolean;
  onNotFoundCLick?: () => void;
  queryLengthToShowNotFound?: number;
  disabled?: boolean;
  optionsHeader?: React.ReactNode;
}

export const Combobox: React.FC<ComboboxProps> = ({
  items,
  className,
  showSelectedLabel = true,
  optionClassName,
  defaultValue,
  onSelect,
  overrideSelectedValue,
  inputProps,
  onChange,
  onBlur,
  isLoading,
  onNotFoundCLick,
  queryLengthToShowNotFound = 5,
  disabled = false,
  optionsHeader,
  type,
}) => {
  const [query, setQuery] = React.useState<string>('');
  const [selectedItem, setSelectedItem] = React.useState<SelectOption | null>(defaultValue ?? null);

  const filteredItems =
    query === ''
      ? items
      : items.filter(item => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <ComboboxReact
      onBlur={onBlur}
      disabled={disabled}
      className={className}
      as="div"
      value={overrideSelectedValue ?? selectedItem}
      onChange={(data?: SelectOption) => {
        if (!data) return;
        setSelectedItem(data);
        onSelect(data.value);
      }}>
      <div className="relative">
        <ComboboxReact.Input
          as={Input}
          svgIcon={
            type === 'search' ? (
              <ComboboxReact.Button
                className={twMerge(
                  'absolute inset-y-0 flex items-center rounded-r-md focus:outline-none focus:ring-transparent',
                  type === 'search' && 'left-2',
                )}>
                {type === 'search' && (
                  <Assets.Search className="h-5 w-5 text-fuji-800" aria-hidden="true" />
                )}
              </ComboboxReact.Button>
            ) : (
              inputProps?.svgIcon
            )
          }
          svgIconRight={
            type === 'select' ? (
              <ComboboxReact.Button
                className={twMerge(
                  'absolute inset-y-0 flex items-center rounded-r-md focus:outline-none focus:ring-transparent',

                  type === 'select' && 'right-0',
                )}>
                {type === 'select' && (
                  <Assets.ChevronDown className="h-5 w-5 text-fuji-800" aria-hidden="true" />
                )}
              </ComboboxReact.Button>
            ) : (
              inputProps?.svgIconRight
            )
          }
          autoComplete="off"
          {...inputProps}
          isDisabled={disabled}
          className={twMerge(
            'focus:border-nusa-200 focus:ring-nusa-200 bg-cabo-50/60 w-full rounded-full border-transparent  px-5 shadow-sm focus:outline-none focus:ring-1',
            !inputProps?.className && 'placeholder:text-fuji-300 py-3',
            inputProps?.className,
            type === 'search' && 'pl-11',
            type === 'select' && 'pr-11',
            inputProps?.hasError && ' focus:border-red-500  !focus:outline-0  !border',
          )}
          onChange={event => {
            setQuery(event.target.value);
            onChange?.(event);
          }}
          displayValue={(item: SelectOption) => (showSelectedLabel ? item?.label || '' : '')}
        />

        <ComboboxReact.Options
          className={twMerge(
            'absolute mt-1 z-20 max-h-80 w-full overflow-auto rounded-2xl bg-mono-100 py-1 text-left shadow-md ring-1 ring-fuji-800 ring-opacity-5 focus:outline-none',
            optionClassName,
          )}>
          {optionsHeader && (
            <ComboboxReact.Option className="my-2" value={null}>
              {optionsHeader}
            </ComboboxReact.Option>
          )}
          {isLoading && (
            <ComboboxReact.Option value={null}>
              <Loader className="mx-auto flex items-center justify-center py-2" color="mint" />
            </ComboboxReact.Option>
          )}
          {(filteredItems.length > 0 || query.length >= 5) && (
            <>
              {query.length >= queryLengthToShowNotFound &&
                filteredItems.length === 0 &&
                !isLoading && (
                  <a onClick={onNotFoundCLick}>
                    <ComboboxReact.Option
                      value={null}
                      className={({ active }) =>
                        twMerge(
                          'relative m-2 cursor-default select-none rounded-md py-2 pl-3 pr-9',
                          active ? 'bg-nusa-50' : 'text-fuji-800',
                        )
                      }>
                      <span className="block truncate">No results found...</span>
                    </ComboboxReact.Option>
                  </a>
                )}
              {filteredItems.length > 0 &&
                filteredItems.map(item => (
                  <ComboboxReact.Option
                    key={item.id}
                    value={item}
                    className={({ active }) =>
                      twMerge(
                        'relative m-2 cursor-default select-none rounded-md py-2 pl-3 pr-9',
                        active ? 'bg-nusa-50' : 'text-fuji-800',
                      )
                    }>
                    <span
                      {...testProps(`search-country-destination-${kebabCase(item.label)}`)}
                      className="block truncate">
                      {item.label}
                    </span>
                  </ComboboxReact.Option>
                ))}
            </>
          )}
        </ComboboxReact.Options>
      </div>
    </ComboboxReact>
  );
};
