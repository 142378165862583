import { useCallback } from 'react';

import { validateApplePaySession } from 'freely-shared-api';
import { useConfigStore } from 'freely-shared-stores';

import { PAYMENT_TYPE, US_PAYMENT_TOKEN_TYPE } from '@packages/types';

import { useCheckoutStore, useGuestStore, useTripStore } from '@store';
import {
  logToSentry,
  onProcessingPaymentModal,
  sendAnalyticsEvent,
  sendAnalyticsUserId,
} from '@utils';

const TAG = 'useApplePay';

export const useApplePay = () => {
  const price = useTripStore(state => state.trip?.price);
  const updateTripPolicyStatus = useTripStore(state => state.updateTripPolicyStatus);
  const updateGuest = useGuestStore(state => state.updateGuest);
  const purchaseUsTrip = useTripStore(state => state.purchaseUsTrip);
  const isApplePayFeatureFlagEnabled = useConfigStore(
    state => !!state.regionSpecificConfig?.NATIVE_PAYMENTS?.APPLE_PAY,
  );
  const setIsApplePayAvailable = useCheckoutStore(state => state.setIsApplePayAvailable);

  const initApplePay = useCallback(() => {
    if (!(window as unknown as { ApplePaySession: any })?.ApplePaySession) return;
    ApplePaySession?.canMakePaymentsWithActiveCard(import.meta.env.VITE_TRAVELX_APPLE_MERCHANT_ID)
      .then(canMakePayments => {
        setIsApplePayAvailable(canMakePayments && isApplePayFeatureFlagEnabled);
      })
      .catch(logToSentry);
  }, [isApplePayFeatureFlagEnabled, setIsApplePayAvailable]);

  const onApplePayButtonClicked = useCallback(() => {
    if (!(window as unknown as { ApplePaySession: any })?.ApplePaySession) return;
    const request: ApplePayJS.ApplePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      total: {
        label: 'Freely',
        type: 'final',
        amount: (price / 100).toString(),
      },
    };

    const session = new ApplePaySession(3, request);
    session.onvalidatemerchant = async event => {
      try {
        const merchantSession = await validateApplePaySession({
          appleUrl: event.validationURL,
          region: { country: 'US' },
        });
        session.completeMerchantValidation(merchantSession);
      } catch (e) {
        logToSentry(e as Error, { tag: TAG });
        updateTripPolicyStatus({ policyStatus: 'ERROR' });
      }
    };

    session.onpaymentauthorized = async event => {
      try {
        updateTripPolicyStatus({ policyStatus: 'NORMAL' });
        if (!event?.payment?.token?.paymentData) {
          return;
        }
        sendAnalyticsEvent('Apple Pay Button Clicked');
        const guestDetails = await updateGuest();
        sendAnalyticsUserId(guestDetails?.userId ?? null, {
          email: guestDetails?.email?.toLowerCase(),
        });
        session.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
        await purchaseUsTrip({
          paymentToken: {
            token: event.payment.token.paymentData,
            paymentMethodNetwork: event.payment.token.paymentMethod.network,
            paymentDisplayName: event.payment.token.paymentMethod.displayName,
            transactionIdentifier: event.payment.token.transactionIdentifier,
          },
          type: event.payment.token.paymentMethod.network as unknown as PAYMENT_TYPE,
          tokenType: US_PAYMENT_TOKEN_TYPE.APPLE_PAY,
        });
        onProcessingPaymentModal();
      } catch (e) {
        logToSentry(e as Error, { tag: TAG });
        updateTripPolicyStatus({ policyStatus: 'ERROR' });
      }
    };

    session.begin();
  }, [price, purchaseUsTrip, updateGuest, updateTripPolicyStatus]);

  return {
    onApplePayButtonClicked,
    initApplePay,
  };
};
