import {
  createQuote as createQuoteApi,
  sendEmailQuote as sendEmailQuoteApi,
  updateQuote as updateQuoteApi,
} from 'freely-shared-api';
import {
  GetStore,
  applyAutoDiscount,
  getRegionState,
  shouldRemoveGroupDiscount,
  useConfigStore,
} from 'freely-shared-stores';

import { EmailQuoteDetails, Trip } from '@packages/types';

import { useFeatureFlagsStore } from '@store/featureFlags';
import { TripStore, useTripStore } from '@store/trip';
import { getClickIdAnonymousId, growsurfApi, logToSentry } from '@utils';

import { useQuoteStore } from './quote.store';

const TAG = 'quote.api';
export const createQuote = async (get: GetStore<TripStore>): Promise<Trip | undefined> => {
  const { setIsCreatingOrUpdatingTrip, setTrip, setPending, setError, trip } = get();
  const { quoteLoadingType, setQuoteLoadingType } = useQuoteStore.getState();
  const region = getRegionState();
  const defaultProductId = useConfigStore.getState().regionSpecificConfig?.DEFAULT_PRODUCT_ID ?? '';
  const referralParticipantId = growsurfApi.getReferrerId();
  const campaignId = await growsurfApi.getCampaignId();

  if (!trip) {
    throw new Error('Trip is not defined');
  }

  if (!region) {
    return;
  }

  const tripPayload: Trip = {
    ...trip,
    productId: defaultProductId,
    ...(region.country === 'US' && {
      us: {
        state: region.state,
        totalTripCost: trip.totalTripCost,
        depositDate: trip.depositDate,
      },
    }),
    referralParticipantId,
    referralCampaignId: campaignId,
  };

  const referralData = await growsurfApi.getReferralData();
  const autoDiscountCode = useFeatureFlagsStore.getState().getAutoDiscount({
    secondaryTravellers: tripPayload?.secondaryTravellers ?? [],
    appliedDiscountCode: referralData?.id ? '' : tripPayload?.promotionCode?.discountCode ?? '',
    destinations: tripPayload?.destinations ?? [],
    referralData,
  });

  try {
    setPending(true);
    setIsCreatingOrUpdatingTrip(true);
    setQuoteLoadingType({
      type: 'quoteLoading',
    });

    const { trip: createdTrip }: any = await createQuoteApi({
      trip: applyAutoDiscount(autoDiscountCode, tripPayload),
      region: region,
      isAuthenticated: false,
      withTrip: true,
    });

    setTrip(createdTrip);
    return createdTrip;
  } catch (err: any) {
    logToSentry(err as Error, { tag: TAG });
    setError(err as Error);
    const isTimeoutError = err?.message === 'Timeout error';
    const showQuoteError = !isTimeoutError || quoteLoadingType.quoteRetryCount >= 1;
    const quoteRetryCount = isTimeoutError
      ? quoteLoadingType.quoteRetryCount + 1
      : quoteLoadingType.quoteRetryCount;

    setQuoteLoadingType({
      quoteRetryCount: quoteRetryCount,
      type: showQuoteError ? 'quoteError' : 'quoteRetry',
    });
  } finally {
    setPending(false);
    setIsCreatingOrUpdatingTrip(false);
  }
};

export const updateQuote = async (get: GetStore<TripStore>) => {
  const { setIsCreatingOrUpdatingTrip, getTrip, setPending, setError, trip } = get();
  const { quoteLoadingType, setQuoteLoadingType } = useQuoteStore.getState();
  const region = getRegionState();
  const referralParticipantId = growsurfApi.getReferrerId();
  const campaignId = await growsurfApi.getCampaignId();

  if (!trip) {
    throw new Error('Trip is not defined');
  }

  if (!region) {
    return;
  }

  try {
    if (!region?.country) {
      throw new Error('No region selected');
    }
    setPending(true);
    setIsCreatingOrUpdatingTrip(true);
    setQuoteLoadingType({
      type: 'quoteLoading',
    });
    const quotePayload: Trip = {
      ...trip,
      destinations: trip.destinations?.map(({ countryCode, countryId, longName }) => ({
        countryCode,
        countryId,
        longName,
      })),
      ...(region.country === 'US' && {
        us: {
          state: region.state,
          totalTripCost: trip.totalTripCost,
          depositDate: trip.depositDate,
        },
      }),
      referralParticipantId,
      referralCampaignId: campaignId,
    };

    const referralData = await growsurfApi.getReferralData();
    const autoDiscountCode = useFeatureFlagsStore.getState().getAutoDiscount({
      secondaryTravellers: trip?.secondaryTravellers ?? [],
      appliedDiscountCode: referralData?.id ? '' : trip?.promotionCode?.discountCode ?? '',
      destinations: trip?.destinations ?? [],
      referralData,
    });

    const queryParams = shouldRemoveGroupDiscount(autoDiscountCode ?? null)
      ? { deleteDiscountCode: true }
      : undefined;

    await updateQuoteApi({
      queryParams,
      quote: applyAutoDiscount(autoDiscountCode ?? null, quotePayload),
      region: region,
      isAuthenticated: false,
    });
    return await getTrip();
  } catch (error: any) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
    const isTimeoutError = error?.message === 'Timeout error';
    const showQuoteError = !isTimeoutError || quoteLoadingType.quoteRetryCount >= 1;
    const quoteRetryCount = isTimeoutError
      ? quoteLoadingType.quoteRetryCount + 1
      : quoteLoadingType.quoteRetryCount;

    setQuoteLoadingType({
      quoteRetryCount: quoteRetryCount,
      type: showQuoteError ? 'quoteError' : 'quoteRetry',
    });
  } finally {
    setPending(false);
    setIsCreatingOrUpdatingTrip(false);
  }
};

export const sendEmailQuote = async (
  emailQuoteDetails: EmailQuoteDetails,
  triggerType: 'emailQuote' | 'abandonCart' = 'emailQuote',
) => {
  const trip = useTripStore.getState().trip;
  const region = getRegionState();
  const { clickId, anonymousId } = getClickIdAnonymousId();
  const emailQuoteData = { ...emailQuoteDetails, clickId, anonymousId };
  if (!trip) {
    throw new Error('Trip is not defined');
  }

  if (!region) {
    throw new Error('Region is not defined');
  }
  try {
    const response = await sendEmailQuoteApi({
      emailQuoteDetails: emailQuoteData,
      quoteId: trip.metaData?.quoteId ?? '',
      region: region,
      triggerType,
    });
    return response && 'Success';
  } catch (error: any) {
    logToSentry(error as Error, { tag: TAG });
    if (error.response.status === 400 && error.response.data && error.response.data.message) {
      if (error.response.data.message.includes('limit')) return 'Limit';
      else return 'Failed';
    }
  }
};
