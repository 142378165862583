import { DateTime, Duration } from 'luxon';

import { DEFAULT_EXCESS_DESCRIPTION_CLAIM_AMOUNT, REGIONS } from '@packages/constants';

import {
  BOOST_CODE,
  Boost,
  GetQuoteForm,
  Region,
  RegionCode,
  SecondaryTraveller,
  TRIP_STATUS,
  TravellersAgeRanges,
  Trip,
  TripDestination,
} from '../../../../types/src';
import {
  getAge,
  getFullDateStr,
  getRegionDateTime,
  isInFutureOrTodayOrYesterday,
  parseISO,
} from '../datetime/datetime';

/**
 * Checking COVID Cancellation Eligibility
 * @param startDate as string
 * @param policyDate as timestamp string
 * @param isUS as boolean
 * @param cancellationSafeDays as number
 */
export const isEligibleForCovidCancellation = (
  startDate: string,
  policyDate: string | undefined,
  currentRegion: RegionCode | null,
  cancellationSafeDays: number,
) => {
  if (!startDate || !policyDate || currentRegion != REGIONS.AU.code || !cancellationSafeDays) {
    return false;
  }
  // trip start date based on region
  const tripStartDate = parseISO(startDate, currentRegion);

  // policy issue date based on region
  const policyDate_timestamp = parseInt(policyDate);
  const policyIssueDate = parseISO(new Date(policyDate_timestamp).toISOString(), currentRegion);

  // Comparing tripStartDate with policyIssueDate
  const days = tripStartDate.diff(policyIssueDate).as('days');
  return cancellationSafeDays < days;
};

// Paid trips without CANCELLED trips
export const isTripPaid = (trip?: Pick<Trip, 'state'> | null) =>
  trip?.state === TRIP_STATUS.PAID || trip?.state === TRIP_STATUS.UPDATING;

export const isPolicyFailed = (trip?: Pick<Trip, 'policyStatus'> | null) =>
  trip?.policyStatus === 'ERROR' || trip?.policyStatus === 'TIMEOUT';

// Paid trips with CANCELLED trips
export const isTripPaidOrCanceled = (trip?: Trip | null) =>
  trip?.state === TRIP_STATUS.PAID ||
  trip?.state === TRIP_STATUS.CANCELLED ||
  trip?.state === TRIP_STATUS.UPDATING;

export const getPayPerDayRate = (boost?: Boost | null) =>
  boost?.toUpdate
    ? boost?.toUpdate?.boostProperty?.payPerDayRate
    : boost?.boostProperty?.payPerDayRate;

/**
 * Checks if the trip has reached within the protection dates.
 * Grabs the region specific date object and adds the number of protection days.
 * If the trip date is before the current date + protection days, the trip is within the protection days.
 * @param trip
 * @param protectionRestrictionDays
 * @param country
 */
export const isTripWithinProtectionDays = (
  trip: Trip,
  protectionRestrictionDays: number,
  country: RegionCode = REGIONS.AU.code,
): boolean => {
  const expiryDate = getRegionDateTime(country).plus({ days: protectionRestrictionDays });

  // We dont want to display trips that have been paid or cancelled past trips
  // so do not filter them out
  if (trip?.state === TRIP_STATUS.PAID || trip?.state === TRIP_STATUS.CANCELLED) {
    return false;
  }
  return parseISO(trip.startDate, country).startOf('day').toMillis() -
    expiryDate.startOf('day').toMillis() <=
    0
    ? true
    : false;
};

/**
 * Returns an array of trip sortKeys that are within the protection days.
 * The protection day is inclusive of the current date
 * @param trips
 * @param protectionRestrictionDays
 * @param country
 */
export const getTripsWithinProtectionDaysIds = (
  trips: Array<Trip>,
  protectionRestrictionDays: number,
  country: RegionCode = REGIONS.AU.code,
): Array<string> => {
  // Some trips can have sortKeys of null or undefined.
  // So remove them.
  const tripsMapNotToNull = trips.filter(t => {
    return !!t?.sortKey;
  });

  // Maps over the trips and returns an array of tripSortkeys
  const tripsToDelete = tripsMapNotToNull.filter(t =>
    isTripWithinProtectionDays(t, protectionRestrictionDays, country),
  );

  return tripsToDelete.map(it => it.sortKey as string);
};

/**
 * Returns true if a user has any paid trips starting from local time of yesterday or after.
 * */
export const hasUserGotPaidTripsFromYesterday = (
  trips: Array<Trip>,
  country: RegionCode = REGIONS.AU.code,
) => {
  return trips.some(t => isTripPaid(t) && isInFutureOrTodayOrYesterday(t.endDate, country));
};

export const isFirstPurchase = (trips?: Trip[] | null) => {
  return !(
    !!trips &&
    trips?.some(
      trip =>
        trip.state === TRIP_STATUS.PAID ||
        trip.state === TRIP_STATUS.UPDATING ||
        trip.state === TRIP_STATUS.WAITING_PAYMENT,
    )
  );
};

export const isTripFreeOfCharge = (trip?: Trip | null): boolean =>
  !!trip?.promotionCode?.isFreeOfCharge;

/**
 * Returns a list of destinations in a string format with the last destination joined by '&'
 * ie 'Bali, Singapore & Australia
 * @param trip
 */
export const getDestinationsLabel = (trip: Trip): string => {
  const destinationsList = trip?.destinations?.map(
    destination => destination.countryName ?? destination.longName,
  );
  const listLength = destinationsList?.length;

  if (!listLength) {
    return '';
  }

  if (listLength === 1) {
    return destinationsList[0] ?? '';
  }

  if (listLength === 2) {
    return destinationsList.join(' & ');
  }

  return destinationsList.slice(0, -1).join(', ') + ' & ' + destinationsList.slice(-1);
};

export const getTripTravelDates = (trip: Trip): string => {
  const startDate = trip?.startDate;
  const endDate = trip?.endDate;

  if (!startDate || !endDate) {
    return '';
  }

  return `${getFullDateStr(startDate)} - ${getFullDateStr(endDate)}`;
};

/**
 * Remove unpaid trips that start date is in the past (< today's date)
 * @param trips
 * @param country
 * @returns Trip[]
 */
export const removePastUnpaidTrips = (
  trips: Trip[],
  country: RegionCode | undefined,
  offset?: number,
) =>
  trips.filter(
    trip =>
      isTripPaidOrCanceled(trip) ||
      parseISO(trip.startDate, country).startOf('day').toMillis() >=
        getRegionDateTime(country)
          .plus({ day: offset ?? 0 })
          .startOf('day')
          .toMillis(),
  );

export const getNumberOfSelectedSecondaryTravellers = (trip: Trip) => {
  return trip?.secondaryTravellers?.filter(it => it.isSelected)?.length ?? 0;
};

/**
 * Finds the eligible status of a trip.
 * If US it finds the pre-trip cancellation boost and the eligible status
 * if AU it defaults to true as AU trips have no pre-trip cancellation product and automatically assumed to be eligible.
 * @param trip
 * @param region
 */
export const isTripEligible = ({ trip, region }: { trip: Trip | null; region?: Region | null }) => {
  if (region?.country !== 'US') {
    return true;
  }

  const preTripCancellationBoost = trip?.boosts?.find(
    it => it.code === BOOST_CODE.PRE_TRIP_CANCELLATION,
  );

  return !!preTripCancellationBoost?.isEligible;
};

export function createNewGetQuoteForm() {
  return {
    tripDates: {
      startDate: '',
      endDate: '',
    },
    destinations: [],
    totalTripCost: '',
    primaryTravellerDOB: '',
    secondaryTravellers: [],
    depositDate: '',
  } as GetQuoteForm;
}

/**
 * Returns true if the trip has exceeded the max trip days
 * @param maxTripDays
 * @param durationInDays
 */
export function hasTripDatesExceededLimit({
  maxTripDays,
  durationInDays,
}: {
  maxTripDays?: number;
  durationInDays: number;
}) {
  return maxTripDays && durationInDays > maxTripDays;
}

/**
 * Compares two arrays of trip destinations
 * @param a
 * @param b
 */

export const deepCompareTripDestinations = (a: TripDestination[], b: TripDestination[]) => {
  if (a.length !== b.length) {
    return false;
  }
  // array have same items
  return a.every(aItem => b.some(bItem => aItem.longName === bItem.longName));
};

/**
 * Calculates the freely paid claim amount example based on excess amount
 * @param excessAmount
 * @param claimAmount
 */
export function getExcessDescription({
  excessAmount,
  claimAmount = DEFAULT_EXCESS_DESCRIPTION_CLAIM_AMOUNT,
}: {
  excessAmount: number;
  claimAmount?: number;
}) {
  const freelyPaidAmount = 600 - excessAmount;
  return `Excess refers to the amount of money deducted from your claim amount. For example, if your excess is $${excessAmount} and you make a claim for $${claimAmount}, Freely will pay you $${freelyPaidAmount}, if it is approved. Any claims below the excess will not be approved.`;
}

/**
 * Returns true if the start date is within the limit
 * ie max trip duration is 548 days. This means that trip start date must be within 548 days of the min available date
 * @param startDate
 * @param minAvailableDate
 * @param maxTripDurationInDays
 * @param region
 */
export function isStartDateWithinLimit({
  startDate,
  minAvailableDate,
  maxDaysFromAvailableDate,
  region,
}: {
  startDate: string;
  minAvailableDate: DateTime;
  maxDaysFromAvailableDate?: number;
  region?: Region | undefined | null;
}) {
  if (!maxDaysFromAvailableDate) {
    // no limit specified so assume its valid
    return true;
  }

  const durationFromMinAvailableDate = Duration.fromObject({ days: maxDaysFromAvailableDate });

  const maxDurationAddedToMinDate = minAvailableDate
    .startOf('day')
    .plus(durationFromMinAvailableDate);

  const startDateToCompare = parseISO(startDate, region?.country ?? 'AU');

  return (
    startDateToCompare.startOf('day').toMillis() <
    maxDurationAddedToMinDate.startOf('day').toMillis()
  );
}
export const getTravellerAgeType = (
  age: number,
  secondaryTravellerAge: { min: number; mid: { min: number; max: number }; max: number },
): TravellersAgeRanges => {
  if (age < secondaryTravellerAge.mid.min) {
    return 'Child';
  } else if (age > secondaryTravellerAge.mid.max) {
    return 'Adult';
  } else {
    return 'Young Adult';
  }
};

type FilterTravellersByDependentArgs = {
  travellers: SecondaryTraveller[];
  type: 'adults' | 'dependants' | 'children';
  defaultMaxDependantAge: number;
  region?: Region | null;
};

/**
 *
 * @param travellers
 * @param type
 * @param defaultMaxDependantAge
 * @param region
 */
export const filterTravellersByDependent = ({
  travellers,
  type,
  defaultMaxDependantAge = 18,
  region,
}: FilterTravellersByDependentArgs) => {
  return travellers?.filter(traveller => {
    if (!traveller?.isSelected) {
      return false;
    }
    const age = getAge(traveller?.dob ?? '', region?.country ?? 'AU');
    const isDependant = traveller.isDependant;

    if (type === 'adults') {
      return !isDependant && age >= defaultMaxDependantAge;
    }

    /**
     * an adult can be classified as a child in COI if dependant checkbox is true
     */
    if (type === 'dependants' || type === 'children') {
      return isDependant || age < defaultMaxDependantAge;
    }

    return false; // Invalid type
  });
};

type GetSortedTravellersListLabelArgs = {
  trip: Trip;
  defaultMaxDependantAge?: number; // 18
  region?: Region | null;
};

/**
 * this format is used by AU
 * e.g.
 * Adults (22, 24)
 * Dependants (2, 15)
 */
export const getSortedTravellersListLabel = ({
  trip,
  defaultMaxDependantAge = 18,
  region,
}: GetSortedTravellersListLabelArgs) => {
  if (!trip?.primaryTraveller?.dob) {
    return ['', ''];
  }
  const regionCode = region?.country ?? 'AU';
  const primaryTravellerAge = getAge(trip?.primaryTraveller?.dob ?? '', regionCode);

  const listOfAdultAges = filterTravellersByDependent({
    travellers: trip?.secondaryTravellers,
    type: 'adults',
    defaultMaxDependantAge,
    region,
  })?.map(adult => getAge(adult?.dob ?? '', regionCode));

  if (primaryTravellerAge >= defaultMaxDependantAge) {
    listOfAdultAges.unshift(primaryTravellerAge);
  }

  const listOfChildrenAge = filterTravellersByDependent({
    travellers: trip?.secondaryTravellers,
    type: 'children',
    defaultMaxDependantAge,
  })?.map(child => getAge(child?.dob ?? '', regionCode));

  return [
    formatTravellerList({
      ages: listOfAdultAges,
      label: 'Adult',
      isShowingAmountOfTravellers: true,
    }),
    formatTravellerList({
      ages: listOfChildrenAge,
      label: 'Child',
      isShowingAmountOfTravellers: false,
    }),
  ];
};

type GetPrimaryAndSecondaryTravellersListLabelArgs = {
  trip: Trip;
  region?: Region | null;
  defaultMaxDependantAge?: number; // 18
};

/**
 * this format is used by US
 */
export const getPrimaryAndSecondaryTravellersListLabel = ({
  trip,
  region,
}: GetPrimaryAndSecondaryTravellersListLabelArgs) => {
  if (!trip?.primaryTraveller?.dob) {
    return ['', ''];
  }

  const regionCode = region?.country ?? 'AU';

  const primaryTravellerAge = getAge(trip?.primaryTraveller?.dob ?? '', regionCode);
  const secondaryTravellersAges = trip?.secondaryTravellers
    ?.filter(t => t?.isSelected)
    ?.map(it => getAge(it?.dob ?? '', regionCode));
  return [
    formatTravellerList({
      ages: [primaryTravellerAge],
      label: 'Primary',
      isShowingAmountOfTravellers: false,
    }),
    formatTravellerList({
      ages: secondaryTravellersAges,
      label: 'Secondary',
      isShowingAmountOfTravellers: false,
    }),
  ];
};

/**
 *
 * @param ages
 * @param label
 */

type FormatTravellerListArgs = {
  ages: number[];
  label: 'Adult' | 'Dependant' | 'Child' | 'Primary' | 'Secondary';
  isShowingAmountOfTravellers?: boolean;
};

export const formatTravellerList = ({
  ages,
  label,
  isShowingAmountOfTravellers = true,
}: FormatTravellerListArgs) => {
  if (ages.length === 0) {
    return '';
  }
  let numeratorPrefix = '';

  let formattedLabel = '';
  if (ages.length > 1 && (label === 'Adult' || label === 'Dependant')) {
    formattedLabel = `${label}s`;
  } else if (ages.length > 1 && label === 'Child') {
    formattedLabel = `Children`;
  } else {
    formattedLabel = label;
  }

  if (isShowingAmountOfTravellers) {
    numeratorPrefix = `${ages.length}`;
  }

  const formattedAges = ages.join(', ');

  return `${numeratorPrefix} ${formattedLabel} (${formattedAges})`.trim();
};

/**
 * utility that returns the group type based on the number of travellers
 * @param totalNumberOfTravellers number
 * @returns 'couple' | 'group' | 'single'
 */
export const calcGroupType = (totalNumberOfTravellers: number) => {
  if (totalNumberOfTravellers > 2) {
    return 'group';
  }

  if (totalNumberOfTravellers === 2) {
    return 'couple';
  }

  return 'single';
};

export const getPrecancellationBoostPrice = (trip: Trip | null) => {
  return trip?.boosts?.find(it => it.code === BOOST_CODE.PRE_TRIP_CANCELLATION)?.price;
};

export const hasPreCancellationBoostPrice = (trip: Trip | null) => {
  const price = getPrecancellationBoostPrice(trip);
  return !!price && price !== '0' && !isNaN(parseFloat(price));
};

/**
 * Filters out the pre-trip cancellation boost if it has no price
 * Currently used until compliance has new copy ready for the pre-trip cancellation boost
 * @param boost
 */
export const filterIneligiblePreCancellation = (boost: Boost) => {
  if (boost.code === BOOST_CODE.PRE_TRIP_CANCELLATION) {
    const price = boost?.price;
    return !!price && price !== '0' && !isNaN(parseFloat(price));
  }

  return true;
};

/**
 * Returns true if the trip has an emc boost that requires redeclaration
 * @param trip
 */
export function shouldRedeclareEmc(trip: Trip | null) {
  const emcBoost = trip?.boosts?.find(it => it?.code === 'EMC');
  if (!emcBoost) {
    return false;
  }

  return !!emcBoost?.boostProperty?.emcData?.some(
    it => !!it?.requireRedeclare && !!it?.isCompleted,
  );
}
