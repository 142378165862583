import { BOOST_CATEGORY } from '@packages/types';

import { useBoostDetailsStore, useTripStore } from '@store';

const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;
useTripStore.subscribe(
  state => state?.trip?.boosts?.find?.(it => it.category === BOOST_CATEGORY.MY_HEALTH)?.boostId,
  async (currentBoostId, previousBoostId) => {
    if (!currentBoostId || currentBoostId === previousBoostId) {
      return;
    }
    await getBoostDetails(currentBoostId);
  },
);
