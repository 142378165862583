import kebabCase from 'lodash/kebabCase';

import { colors } from 'freely-shared-design';
import { useDestinationsStore } from 'freely-shared-stores';
import { isSuggestedDestinationSelected } from 'freely-shared-utils';

import { TripDestination } from '@packages/types';

import { Assets } from '@assets';
import { Button, Loader, Text } from '@components';
import { i18n } from '@i18n';
import { testProps } from '@utils';

export interface TrendingDestinationProps {
  selectedDestinations: TripDestination[];
  onToggleDestination: (destination: TripDestination) => void;
}

export const TrendingDestinations: React.FC<TrendingDestinationProps> = ({
  selectedDestinations,
  onToggleDestination,
}) => {
  const loadingDestinations = useDestinationsStore(state => state.pending);
  const trendingDestinations = useDestinationsStore(state => state.trendingDestinations);

  return (
    <>
      <Text variant="subTitle-20/sb" className="py-6 text-center">
        {i18n.t('selectDestinations.trendingDestinations.title')}
      </Text>
      <div className="my-4 flex-1 pb-4 text-center">
        {loadingDestinations ? (
          <Loader />
        ) : (
          <ul role="list" className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
            {trendingDestinations?.map(destination => {
              const isSelected = isSuggestedDestinationSelected(destination, selectedDestinations);
              return (
                <li
                  key={`${destination.countryCode}-${destination.longName}`}
                  className="col-span-1">
                  <Button
                    {...testProps(`select-country-destination-${kebabCase(destination.longName)}`)}
                    withEllipsis
                    title={destination.longName}
                    size="md"
                    onClick={() => {
                      onToggleDestination(destination);
                    }}
                    IconRight={isSelected ? Assets.CloseCircleLight : undefined}
                    IconRightProps={{ fill: colors.fuji[800] }}
                    fontVariant="body-16/m"
                    textAlign="left"
                    variant={isSelected ? 'mint' : 'snow'}
                    disabled={false}
                    className="w-full text-left">
                    {destination.longName}
                  </Button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};
