import { CreateStore, createStore } from 'freely-shared-stores';

import {
  AddSpecifiedItemToBoostArgument,
  Boost,
  BoostArgument,
  CalendarData,
  PaymentOption,
  PreviouslySelectedCover,
  PrimaryTraveller,
  RemoveSpecifiedItemFromBoostArgument,
  ScreeningResultRequest,
  ScreeningResultResponse,
  SecondaryTraveller,
  SelectExtraCancellationBoostArument,
  TRIP_STATUS,
  ToggleBoostStatusArgument,
  Trip,
  TripDestination,
  UpdateBoostArgument,
  UpdateTripStatusArgument,
} from '@packages/types';

import { useCheckoutStore } from '@store/checkout';
import { useEmcStore } from '@store/emc';
import { TOptionalFields } from '@types';

import * as quoteApi from '../quote/quote.api';
import * as tripApi from './trip.api';
import * as tripUtils from './trip.util';

export interface TripState {
  trip: Trip;
  error: Error | undefined;
  pending: boolean;
  isCreatingOrUpdatingTrip: boolean;
  disabledBoostCardIds: Array<string>;
  displayBoostUpsellCodes: Array<string>;
  addedSelectedCover: PreviouslySelectedCover | null;
  isBoostUpdating: boolean;
}

export interface TripActions {
  setTrip: (trip?: Trip) => void;
  getTrip: () => Promise<Trip | undefined>;
  resetTripState: () => void;
  setPending: (pending: boolean) => void;
  setError: (error: Error | undefined) => void;
  toggleTripDestination: (destination: TripDestination) => void;
  setTripDestinations: (destinations: TripDestination[]) => void;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  setDepositDate: (depositDate: string) => void;
  updateBoost: (updateBoostArgument: UpdateBoostArgument) => Promise<Boost | undefined>;
  applyDiscountCode: (
    discountCode: string,
    email?: string,
    ignoreAnalytics?: boolean,
  ) => Promise<Trip | undefined>;
  removeDiscountCode: () => Promise<Trip | undefined>;
  setDisabledBoostCardIds: (listOfIds: string[]) => void;
  setTotalTripCost: (tripCost: number) => void;
  setTogglingBoostInTrip: (toggleBoostArgument: ToggleBoostStatusArgument) => void;
  removeBoost: (removeBoostArgument: BoostArgument) => Promise<void>;
  addSpecifiedItem: (
    addSpecifiedItemToBoostArgument: AddSpecifiedItemToBoostArgument,
  ) => Promise<Boost | void>;
  removeSpecifiedItem: (
    removeSpecifiedItemsFromBoostArgument: RemoveSpecifiedItemFromBoostArgument,
  ) => Promise<void>;
  toggleOffMutuallyExclusiveBoosts: (boostArgument: BoostArgument) => Promise<void>;
  addBoost: (addBoostArgument: UpdateBoostArgument) => Promise<Boost | undefined>;
  saveScreeningResult: (
    data: TOptionalFields<
      ScreeningResultRequest,
      | 'firstName'
      | 'lastName'
      | 'travellerId'
      | 'result'
      | 'boostId'
      | 'primaryTravellerId'
      | 'country'
      | 'isCompleted'
    >,
  ) => Promise<ScreeningResultResponse | undefined>;
  selectExtraCancellation: (
    selectExtraCancellationBoostArgument: SelectExtraCancellationBoostArument,
  ) => Promise<void>;
  setPrimaryTraveller: (primaryTraveller: PrimaryTraveller) => void;
  setSecondaryTravellers: (secondaryTravellers: SecondaryTraveller[]) => void;
  syncTrip: () => Promise<Trip | undefined>;
  validateTripDuration: ({ durationInDays }: CalendarData) => boolean;
  processFreeOfChargePromotion: () => Promise<void>;
  purchaseUsTrip: (paymentOption: PaymentOption) => Promise<void>;
  updateTripPolicyStatus: (argument: UpdateTripStatusArgument) => void;
  addBoostUpsellCode: (boostCode: string) => void;
  setAddedSelectedCover: (selectedCover: PreviouslySelectedCover | null) => void;
  revertExtraCancellation: () => Promise<void>;
  validateTripStartDate: (startDate: string) => boolean;
  setIsCreatingOrUpdatingTrip: (isCreatingOrUpdatingTrip: boolean) => void;
  setIsBoostUpdating: (isBoostUpdating: boolean) => void;
}

export type TripStore = TripState & TripActions;

export const initialState: TripState = {
  pending: false,
  isBoostUpdating: false,
  disabledBoostCardIds: [],
  error: undefined,
  displayBoostUpsellCodes: [],
  addedSelectedCover: null,
  isCreatingOrUpdatingTrip: false,
  trip: {
    sortKey: null,
    primaryTravellerId: null,
    primaryTraveller: null,
    secondaryTravellers: [],
    state: TRIP_STATUS.NO_BOOST,
    startDate: '',
    endDate: '',
    destinations: [],
    boosts: [],
    price: 0,
    pdsLink: null,
    presignedCoiLink: null,
    hasAddedBoosts: false,
    pendingUpdates: null,
    isTripStarted: false,
    policyStatus: 'NORMAL',
    productId: '',
  },
};

export const tripStore: CreateStore<TripStore> = (set, get) => ({
  ...initialState,
  toggleTripDestination: destination => tripUtils.toggleTripDestination(set, get, destination),
  setTripDestinations: destinations => tripUtils.setTripDestinations(set, destinations),
  setTrip: trip => tripUtils.setTrip(set, get, trip),
  setIsCreatingOrUpdatingTrip: isCreatingOrUpdatingTrip => {
    set(state => {
      state.isCreatingOrUpdatingTrip = isCreatingOrUpdatingTrip;
    });
  },
  resetTripState: () => set(initialState, false),
  saveScreeningResult: async data => await tripApi.saveScreeningResult(get, data),
  setStartDate: startDate => {
    set(state => {
      state.trip.startDate = startDate;
    });
  },
  setEndDate: endDate => {
    set(state => {
      state.trip.endDate = endDate;
    });
  },
  setDepositDate: depositDate => {
    set(state => {
      state.trip.depositDate = depositDate;
    });
  },

  setTotalTripCost: tripCost => {
    set(state => {
      state.trip.totalTripCost = tripCost;
    });
  },
  getTrip: async () => await tripApi.fetchTrip(get),
  setPending: pending => {
    set(state => {
      state.pending = pending;
    });
  },
  setIsBoostUpdating: isBoostUpdating => {
    set(state => {
      state.isBoostUpdating = isBoostUpdating;
    });
  },
  setError: error => {
    set(state => {
      state.error = error;
    });
  },
  updateBoost: async updateBoostArgument => {
    useCheckoutStore.getState().setIsLegalConsentChecked(false);
    const isPaymentOpen = useCheckoutStore.getState().openSection === 'payment';
    isPaymentOpen && useCheckoutStore.getState().setOpenSection('legalStuff');
    return tripApi.updateBoostInTrip(get, updateBoostArgument);
  },
  applyDiscountCode: async (discountCode, email, ignoreAnalytics) => {
    return await tripApi.applyDiscountCodeInTrip(get, discountCode, email, ignoreAnalytics);
  },
  removeDiscountCode: async () => await tripApi.removeDiscountCodeFromTrip(get),
  setDisabledBoostCardIds: listOfIds =>
    set(state => {
      state.disabledBoostCardIds = listOfIds;
    }),
  setTogglingBoostInTrip: toggleBoostArgument =>
    tripUtils.toggleBoostStatusInTrip(set, toggleBoostArgument),
  removeBoost: async removeBoostArgument => {
    await tripUtils.removeBoostInTrip(get, removeBoostArgument);
  },
  addSpecifiedItem: async addSpecifiedItemToBoostArgument =>
    await tripUtils.addSpecifiedItemToTrip(get, addSpecifiedItemToBoostArgument),
  removeSpecifiedItem: async removeSpecifiedItemFromBoostArgument => {
    await tripUtils.removeSpecifiedItemFromTrip(get, removeSpecifiedItemFromBoostArgument);
  },
  toggleOffMutuallyExclusiveBoosts: async boostArgument => {
    await tripUtils.toggleOffMutuallyExclusiveBoostsInTrip(get, boostArgument);
  },
  addBoost: async addBoostArgument => {
    return await tripUtils.addBoostToTrip(get, addBoostArgument);
  },
  selectExtraCancellation: async selectExtraCancellationBoostArgument =>
    await tripUtils.selectExtraCancellation(get, selectExtraCancellationBoostArgument),
  setPrimaryTraveller: primaryTraveller => {
    set(state => {
      state.trip.primaryTraveller = primaryTraveller;
    });
  },
  setSecondaryTravellers: secondaryTravellers =>
    set(state => {
      state.trip.secondaryTravellers = secondaryTravellers;
    }),
  syncTrip: async () => {
    const {
      trip: { sortKey },
    } = get();
    useCheckoutStore.getState().setOpenSection('travellerDetails');
    useCheckoutStore.getState().setIsLegalConsentChecked(false);
    if (sortKey) {
      // reset name changed traveller when the trip is updated
      useEmcStore.getState().resetNameChangedTraveller();
      return await quoteApi.updateQuote(get);
    } else {
      return await quoteApi.createQuote(get);
    }
  },
  validateTripDuration: ({ durationInDays }) => tripUtils.validateTripDuration({ durationInDays }),
  validateTripStartDate: startDate => tripUtils.validateTripStartDate(startDate),
  processFreeOfChargePromotion: async () => await tripApi.processFreeOfChargePromotionInTrip(get),
  purchaseUsTrip: async paymentOption => await tripApi.purchaseUsTrip(get, paymentOption),
  updateTripPolicyStatus: ({ status, policyStatus, policyNumber }) => {
    set(state => {
      if (status) {
        state.trip.state = status;
      }
      if (policyStatus) {
        state.trip.policyStatus = policyStatus;
      }
      if (policyNumber) {
        state.trip.policy = { ...state.trip.policy, policyNumber };
      }
    });
  },
  addBoostUpsellCode: boostCode => {
    set(state => {
      state.displayBoostUpsellCodes.push(boostCode);
    });
  },
  setAddedSelectedCover: selectedCover => {
    set(state => {
      if (selectedCover === null) {
        state.addedSelectedCover = null;
        return;
      }

      state.addedSelectedCover = { ...state.addedSelectedCover, ...selectedCover };
    });
  },
  revertExtraCancellation: async () => tripUtils.revertSelectedExtraCancellation(get),
});

export const useTripStore = createStore(tripStore, {
  name: 'useTripStore',
});
