import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import {
  getRegionSelector,
  isUSRegionSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { validate, validatePostCode, validateState } from 'freely-shared-utils';

import { REGIONS } from '@packages/constants';
import { GuestDetailsForm, RegionValidationRules } from '@packages/types';

import { i18n } from '@i18n';
import { useCheckoutStore } from '@store';
import { setIsManualAddressSectionSelector } from '@store/checkout/checkout.selectors';

import { Combobox } from '../combobox';
import { Input } from '../input';
import { Text } from '../text';

//https://stackoverflow.com/questions/71296535/how-to-remove-arrow-on-input-type-number-with-tailwind-css
const removeNativeArrowClass =
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ';

export const ManualAddress: React.FC = () => {
  const setIsManualAddressSectionOpen = useCheckoutStore(setIsManualAddressSectionSelector);
  const isUsRegion = useRegionStore(isUSRegionSelector);
  const region = useRegionStore(getRegionSelector);
  const states = region?.country && REGIONS[region?.country]['states']?.map(it => it.code);
  const countryCode = useConfigStore(state => state?.regionSpecificConfig?.CODE) ?? '';
  const regionSpecificValidation =
    useConfigStore(state => state?.regionSpecificConfig?.VALIDATION) ??
    ({} as RegionValidationRules);

  const addressOnePlaceholder = i18n.t('checkout.address.input.line1.placeholder');
  const addressTwoPlaceholder = i18n.t('checkout.address.input.line2.placeholder');
  const addressThreePlaceholder = i18n.t('checkout.address.input.line3.placeholder');
  const addressFourPlaceholder = i18n.t('checkout.address.input.line4.placeholder');
  const addressFivePlaceholder = i18n.t('checkout.address.select.placeholder');

  const stateOptions = (states || []).map((state, index) => ({
    id: state + '_' + index,
    label: state,
    value: state,
  }));

  const {
    watch,
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
    getFieldState,
  } = useFormContext<GuestDetailsForm>();
  const userSelectedState = stateOptions?.find(it => {
    const userState = region?.state ?? getValues('addressState') ?? '';
    return it.value === userState;
  });

  const undoManualEntry = () => {
    setValue('addressData', undefined);
    setValue('addressCity', '');
    setValue('addressPostCode', '');
    setValue('addressLine1', '');
    setValue('addressLine2', '');
    !isUsRegion && setValue('addressState', '');
    setIsManualAddressSectionOpen(false);
  };

  return (
    <div>
      <Text className="mb-3" variant="h4-24/sb">
        {i18n.t('checkout.address.subtitle')}
      </Text>
      <Text
        onClick={undoManualEntry}
        className="underline cursor-pointer !text-sm mb-3"
        variant="body-16/r">
        {i18n.t('checkout.address.description')}
      </Text>
      <Text variant="body-16/r">{i18n.t('checkout.text_address')}</Text>
      <Input
        isSuccess={!getFieldState('addressLine1').invalid && !!watch('addressLine1')}
        containerProps={{ className: 'mb-2' }}
        placeholder={addressOnePlaceholder}
        {...register('addressLine1', {
          validate: v => validate('addressLine', v ?? '') ?? undefined,
        })}
        hasError={!!errors.addressLine1}
        errorMessage={errors?.addressLine1?.message}
      />
      <Input
        isSuccess={!getFieldState('addressLine2').invalid && !!watch('addressLine2')}
        containerProps={{ className: 'mb-2' }}
        {...register('addressLine2', {
          validate: v => validate('addressLine2', v ?? '') ?? undefined,
        })}
        placeholder={addressTwoPlaceholder}
        hasError={!!errors.addressLine2}
        errorMessage={errors?.addressLine2?.message}
      />
      <Input
        isSuccess={!getFieldState('addressCity').invalid && !!watch('addressCity')}
        containerProps={{ className: 'mb-3' }}
        placeholder={addressThreePlaceholder}
        {...register('addressCity', {
          required: 'City/Suburb is required',
          validate: v => {
            if (!v?.trim()) {
              return 'City/Suburb is required';
            }
          },
        })}
        hasError={!!errors.addressCity}
        errorMessage={errors?.addressCity?.message}
      />
      <div>
        <Input
          isSuccess={!getFieldState('addressPostCode').invalid && !!watch('addressPostCode')}
          containerProps={{ className: 'mb-3' }}
          placeholder={addressFourPlaceholder}
          type="number"
          labelProps={{ children: 'Postcode' }}
          className={twMerge('flex-1', removeNativeArrowClass)}
          {...register('addressPostCode', {
            validate: v => validatePostCode(regionSpecificValidation, v),
          })}
          hasError={!!errors.addressPostCode}
          errorMessage={errors?.addressPostCode?.message}
        />
        <Controller
          control={control}
          name="addressState"
          rules={{
            validate: v => validateState(countryCode, v),
          }}
          render={({ field: { onChange } }) => (
            <Combobox
              className="mb-3"
              defaultValue={userSelectedState}
              disabled={isUsRegion}
              items={stateOptions}
              type="select"
              onSelect={onChange}
              inputProps={{
                isDisabled: isUsRegion,
                placeholder: addressFivePlaceholder,
                labelProps: { children: i18n.t('checkout.address.select.label') },
                hasError: !!errors.addressState,
                errorMessage: errors?.addressState?.message,
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
