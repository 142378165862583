import {
  GetStore,
  SetStore,
  getRegionSpecificTripDurationRule,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import {
  consolidateDateRanges,
  createSortKeyByUUID,
  getBoostSelectedExtraCover,
  getDurationInMonths,
  getDurationOfDatesInDays,
  getDurationOfRangesInDays,
  getExistingSpecifiedItems,
  getRegionDateTime,
  getSpecifiedItemsAfterRemove,
  hasTripDatesExceededLimit,
  isBoostExtraCancellation,
  isBoostSpecifiedItems,
  isPayPerDayBoost,
  isStartDateWithinLimit,
  sortDateRangesInAscendingOrder,
} from 'freely-shared-utils';

import {
  AddSpecifiedItemToBoostArgument,
  BOOST_UI_STATUS,
  Boost,
  BoostArgument,
  CalendarData,
  RemoveSpecifiedItemFromBoostArgument,
  SecondaryTraveller,
  SelectExtraCancellationBoostArument,
  ToggleBoostStatusArgument,
  Trip,
  TripDestination,
  UpdateBoostArgument,
} from '@packages/types';

import { sendAnalyticsECommerce, sendAnalyticsEvent } from '@utils';

import { useModalStore } from '../modal/modal.store';
import { TripStore, initialState } from './trip.store';

export const getTripBoosts = (get: GetStore<TripStore>) => {
  const { trip } = get();
  if (!trip?.boosts) {
    return;
  }

  return trip?.boosts;
};

export const getSelectedBoost = (get: GetStore<TripStore>, { boostId }: BoostArgument) => {
  const { trip } = get();
  if (!trip?.boosts) {
    return;
  }

  return trip?.boosts?.find(it => it.boostId === boostId);
};

export const toggleTripDestination = (
  set: SetStore<TripStore>,
  get: GetStore<TripStore>,
  destination: TripDestination,
) => {
  const { trip } = get();
  if (trip.destinations.some(d => d.longName === destination.longName)) {
    set(state => {
      state.trip.destinations = trip.destinations.filter(d => d.longName !== destination.longName);
    });
  } else {
    set(state => {
      state.trip.destinations.push(destination);
    });
  }
};

export const setTrip = (set: SetStore<TripStore>, get: GetStore<TripStore>, trip?: Trip) => {
  const { trip: prevTrip } = get();
  let newTrip = trip || initialState.trip;

  // primary traveller residency is not persisted in BE, so we need to keep it in the store
  if (
    trip?.primaryTraveller &&
    prevTrip.primaryTraveller &&
    prevTrip.primaryTraveller.isResident !== undefined
  ) {
    newTrip = {
      ...newTrip,
      primaryTraveller: {
        ...trip.primaryTraveller,
        isResident: prevTrip.primaryTraveller?.isResident,
      },
      secondaryTravellers: trip?.secondaryTravellers?.map(it => {
        return {
          ...it,
        };
      }),
    };
  }

  set(state => {
    state.trip = newTrip;
  });
};

export const setTripDestinations = (set: SetStore<TripStore>, destinations: TripDestination[]) => {
  set(state => {
    state.trip.destinations = destinations;
  });
};

export const toggleBoostStatusInTrip = (
  set: SetStore<TripStore>,
  { boostId, status }: ToggleBoostStatusArgument,
) => {
  set(state => {
    const boostToUpdate = state?.trip?.boosts?.find(it => it.boostId === boostId);
    if (boostToUpdate) {
      boostToUpdate.toggleStatus = status;
    }
  });
};

/**
 * Removes the specified item from the boost.
 * If the boost is the last to be removed,
 * the boost will be toggled to isAdded of false.
 * @param get
 * @param boostId
 * @param itemIdToBeRemoved
 */
export const removeSpecifiedItemFromTrip = async (
  get: GetStore<TripStore>,
  { boostId, itemIdToBeRemoved }: RemoveSpecifiedItemFromBoostArgument,
) => {
  const { updateBoost } = get();
  const selectedBoost = getSelectedBoost(get, { boostId });
  const isSpecifiedItem = isBoostSpecifiedItems(selectedBoost);

  if (!selectedBoost || !isSpecifiedItem) {
    return;
  }

  const specifiedItemsAfterRemove = getSpecifiedItemsAfterRemove(itemIdToBeRemoved, selectedBoost);
  const isAddedData: { isAdded?: boolean } = {};
  // This must be the last item to be removed,
  // so we toggle the isAdded to false.
  if (!specifiedItemsAfterRemove?.length) {
    isAddedData.isAdded = false;
  }

  const updatePayload = {
    isSelected: true,
    boostProperty: {
      specifiedItems: specifiedItemsAfterRemove,
    },
    ...isAddedData,
  };

  await updateBoost({ boostId, updatePayload });
};

/**
 * Adds a specified item to the luggage boost.
 * It takes the existing specified items and appends the new item
 * @param get
 * @param boostId
 * @param specifiedBoostItemToAdd
 */
export const addSpecifiedItemToTrip = async (
  get: GetStore<TripStore>,
  { boostId, specifiedBoostItemToAdd }: AddSpecifiedItemToBoostArgument,
) => {
  const { updateBoost } = get();
  const selectedBoost = getSelectedBoost(get, { boostId });
  const isSpecifiedItem = isBoostSpecifiedItems(selectedBoost);

  if (!selectedBoost || !isSpecifiedItem) {
    return;
  }

  const existingSpecifiedItems = getExistingSpecifiedItems(selectedBoost);
  const updatePayload = {
    isSelected: true,
    isAdded: true,
    boostProperty: {
      specifiedItems: [...existingSpecifiedItems, specifiedBoostItemToAdd],
    },
  };
  return await updateBoost({ boostId, updatePayload });
};

/**
 * Adds a boost to trip.
 * Can also be used to for pay-per-day boosts as selecting dates will add the boost to trip.
 * Can also be used to change pay-per-day dates to a boost with already selected days
 * @param get
 * @param boostId
 * @param updatePayload
 */
export const addBoostToTrip = async (
  get: GetStore<TripStore>,
  { boostId, updatePayload }: UpdateBoostArgument,
) => {
  const {
    updateBoost,
    setTogglingBoostInTrip,
    toggleOffMutuallyExclusiveBoosts,
    setDisabledBoostCardIds,
  } = get();
  const selectedBoost = getSelectedBoost(get, { boostId });
  const mutuallyExclusiveBoostIds = selectedBoost?.boostProperty?.mutuallyExclusiveBoostIds;

  sendAnalyticsECommerce('Product Added');

  if (!selectedBoost) {
    return;
  }

  /**
   * Validation for pay per day boosts
   * Checks if the new update payload is different to what is already saved
   * Only makes the update if the dates are different
   */

  let shouldUpdatePPD = false;

  if (Array.isArray(updatePayload?.startEndDates)) {
    const sortedUpdateDates = updatePayload.startEndDates.slice().sort((a, b) => {
      if (a.startDate < b.startDate) return -1;
      if (a.startDate > b.startDate) return 1;
      return 0;
    });

    const sortedOldDates = (selectedBoost?.toUpdate?.startEndDates ?? selectedBoost.startEndDates)
      .slice()
      .sort((a, b) => {
        if (a.startDate < b.startDate) return -1;
        if (a.startDate > b.startDate) return 1;
        return 0;
      });

    shouldUpdatePPD = JSON.stringify(sortedUpdateDates) !== JSON.stringify(sortedOldDates);
  }

  if (Array.isArray(updatePayload?.startEndDates) && !shouldUpdatePPD) {
    for (const newDateRange of updatePayload.startEndDates) {
      const newStartDate = newDateRange?.startDate ?? '';
      const newEndDate = newDateRange?.endDate ?? '';

      const oldStartEndDates =
        selectedBoost?.toUpdate?.startEndDates ?? selectedBoost.startEndDates;

      for (const oldDateRange of oldStartEndDates) {
        const oldStartDate = oldDateRange?.startDate ?? '';
        const oldEndDate = oldDateRange?.endDate ?? '';

        if (newStartDate !== oldStartDate || newEndDate !== oldEndDate) {
          shouldUpdatePPD = true;
          break; // Exit the loop if any difference is found
        }
      }
    }
  }

  if (!shouldUpdatePPD && isPayPerDayBoost(selectedBoost)) {
    return;
  }

  if (!selectedBoost?.isMandatory) {
    sendAnalyticsEvent('Extra Toggled On', { extraTitle: selectedBoost?.name });

    const boostType = isPayPerDayBoost(selectedBoost) ? 'PPD' : 'trip-wide';
    const currentBoostDuration = selectedBoost?.toUpdate?.duration ?? selectedBoost?.duration ?? 0;
    const isEditMode = currentBoostDuration > 0;
    const eventName = isEditMode ? 'Trip Boost Edited' : 'Trip Boost Added';

    if (boostType === 'trip-wide') {
      sendAnalyticsEvent(eventName, {
        name: selectedBoost?.name,
        boostType,
        price: Number(selectedBoost?.price) / 100, // into dollars
      });
    } else {
      // PPD
      sendPPDAnalytics({
        boost: selectedBoost,
        startEndDates: updatePayload?.startEndDates,
      });
    }
  }

  const tripAttributes = {
    ...updatePayload,
    isSelected: true,
    isAdded: true,
  };
  // disables any mutually exclusive boost cards
  if (mutuallyExclusiveBoostIds && mutuallyExclusiveBoostIds.length > 0) {
    setDisabledBoostCardIds(mutuallyExclusiveBoostIds);
  }
  setTogglingBoostInTrip({ boostId, status: BOOST_UI_STATUS.ADDING });
  const promiseResponse = await Promise.all([
    await updateBoost({ boostId, updatePayload: tripAttributes }),
    await toggleOffMutuallyExclusiveBoosts({ boostId }),
  ]);
  setTogglingBoostInTrip({ boostId, status: null });
  setDisabledBoostCardIds([]);
  return promiseResponse[0];
};

function sendPPDAnalytics({
  boost,
  startEndDates,
}: {
  boost?: Boost;
  startEndDates?: { startDate: string; endDate: string }[];
}) {
  const name = boost?.name ?? '';
  const boostType = 'PPD';

  const currentBoostDuration = boost?.toUpdate?.duration ?? boost?.duration ?? 0;
  const isEditMode = currentBoostDuration > 0;
  const region = useRegionStore.getState().region;
  const isMultiPayPerDay = region?.country === 'AU';

  const selectedRanges = startEndDates ?? [];
  const selectedDuration = boost?.toUpdate?.startEndDates[0];
  const newDuration = isMultiPayPerDay
    ? getDurationOfRangesInDays(selectedRanges, region?.country)
    : getDurationOfDatesInDays(
        selectedDuration?.startDate ?? '',
        selectedDuration?.endDate ?? '',
        region?.country,
      );

  const payPerDayRate =
    boost?.toUpdate?.boostProperty?.payPerDayRate ?? boost?.boostProperty?.payPerDayRate ?? 0;

  const price = (newDuration * payPerDayRate) / 100; // into dollars

  if (isEditMode) {
    sendAnalyticsEvent(`Trip Boost Edited`, {
      name,
      boostType,
      price,
      oldDays: currentBoostDuration,
      newDays: newDuration,
    });
    return;
  }

  const consolidateDateRangesResult = consolidateDateRanges(selectedRanges, region);
  const dateRanges = isMultiPayPerDay
    ? sortDateRangesInAscendingOrder(consolidateDateRangesResult)
    : [{ startDate: selectedDuration?.startDate, endDate: selectedDuration?.endDate }];

  const analyticsDateRanges = dateRanges.map(item => ({
    fromDate: item.startDate ?? '',
    toDate: item.endDate ?? '',
    numOfDays: getDurationOfDatesInDays(item.startDate ?? '', item.endDate ?? '', region?.country),
  }));

  sendAnalyticsEvent(`Trip Boost Added`, {
    name,
    boostType,
    price,
    dateRanges: analyticsDateRanges,
  });
}

/**
 * Used to remove a boost from a trip.
 * Specified items is an exception and must remove all items from boost.
 * @param get
 * @param args
 */
export const removeBoostInTrip = async (get: GetStore<TripStore>, args: BoostArgument) => {
  const { updateBoost, setTogglingBoostInTrip, setAddedSelectedCover } = get();
  const selectedBoost = getSelectedBoost(get, args);
  const isBoostAdded = selectedBoost?.toUpdate?.isAdded ?? selectedBoost?.isAdded;

  sendAnalyticsECommerce('Product Removed');

  if (!isBoostAdded) {
    //boost is not added
    //so we can not remove.
    return;
  }

  if (!selectedBoost?.isMandatory) {
    sendAnalyticsEvent('Extra Toggled Off', { extraTitle: selectedBoost?.name });

    const boostType = isPayPerDayBoost(selectedBoost) ? 'PPD' : 'trip-wide';
    sendAnalyticsEvent('Trip Boost Removed', { boostType, name: selectedBoost?.name });
  }

  const boostRemovePayload: Partial<Boost> = {
    isAdded: false,
    isSelected: true,
  };

  /**
   *When removing specifiedItems boost from the trip
   *We must remove all specified items
   */
  if (isBoostSpecifiedItems(selectedBoost)) {
    boostRemovePayload.boostProperty = { specifiedItems: [] };
  }

  //We must remove the cache of the selected cover when the user removes extra cancellation from trip.
  if (isBoostExtraCancellation(selectedBoost)) {
    setAddedSelectedCover(null);
  }

  setTogglingBoostInTrip({ boostId: args.boostId, status: BOOST_UI_STATUS.REMOVING });
  await updateBoost({ boostId: args.boostId, updatePayload: boostRemovePayload });
  setTogglingBoostInTrip({ boostId: args.boostId, status: null });
};

/**
 * In order to get the extra cancellation price, we must add it to the boostProperty.
 * It will only add the selected value to the boostProperty.
 * If we want to add the extraCancellation boost to trip, it must be done in another call.
 * @param get
 * @param boostId
 * @param newValue
 */
export const selectExtraCancellation = async (
  get: GetStore<TripStore>,
  { boostId, newValue }: SelectExtraCancellationBoostArument,
) => {
  const { updateBoost } = get();
  const selectedBoost = getSelectedBoost(get, { boostId });
  const selectedExtraCover = selectedBoost?.boostProperty?.selectedExtraCover;
  if (newValue === selectedExtraCover || !selectedBoost) {
    return;
  }

  const updatePayload = {
    isAdded: false,
    isSelected: true,
    boostProperty: {
      selectedExtraCover: newValue,
    },
  };
  await updateBoost({ boostId, updatePayload });
};

/**
 * Checks to see if the boost selected has any mutually exclusive boostIds.
 * If ids are present we must disable all boosts with the corresponding ids
 * and remove them from trip.
 * @param get
 * @param boostId
 */
export const toggleOffMutuallyExclusiveBoostsInTrip = async (
  get: GetStore<TripStore>,
  { boostId }: BoostArgument,
) => {
  const { removeBoost } = get();
  const tripBoosts = getTripBoosts(get);
  const selectedBoost = getSelectedBoost(get, { boostId });
  const mutuallyExclusiveBoostIds = selectedBoost?.boostProperty?.mutuallyExclusiveBoostIds;

  if (
    !selectedBoost ||
    !mutuallyExclusiveBoostIds ||
    !mutuallyExclusiveBoostIds.length ||
    !tripBoosts
  ) {
    return;
  }

  // Checks to see if there are any added mutually exclusive ids first
  const boostsIdsToToggleOff = tripBoosts
    ?.filter(it => mutuallyExclusiveBoostIds?.includes(it.boostId) && it?.isAdded)
    .map(({ boostId }) => ({ boostId }));

  // Toggles them off.
  if (boostsIdsToToggleOff && boostsIdsToToggleOff?.length > 0) {
    await Promise.all(
      boostsIdsToToggleOff.map(async (it, index, arr) => {
        await removeBoost({
          boostId: it.boostId,
          isRefreshingTrip: index === arr.length - 1,
        });
      }),
    );
  }
};

export const getTripTravelDates = (trip: Trip): string => {
  const { getFullDateStr } = regionDateUtils();

  const startDate = trip?.startDate;
  const endDate = trip?.endDate;

  if (!startDate || !endDate) {
    return '';
  }

  return `${getFullDateStr(startDate)} - ${getFullDateStr(endDate)}`;
};

export const createNewSecondaryTraveller = ({
  sortKey = createSortKeyByUUID(),
  firstName = '',
  lastName = '',
  dob = '',
  isSelected = true,
  isResident = false,
  isDependant = false,
}): SecondaryTraveller => ({
  sortKey,
  firstName,
  lastName,
  dob,
  isSelected,
  isResident,
  isDependant,
});

export const validateTripStartDate = (startDate: string): boolean => {
  const openModal = useModalStore.getState().openModal;
  const region = useRegionStore.getState().region;
  const currentDate = getRegionDateTime(region?.country);
  const maxStartDateOffset =
    useConfigStore.getState().regionSpecificConfig?.RULES?.MAX_START_DATE_OFFSET;

  if (
    !isStartDateWithinLimit({
      startDate,
      maxDaysFromAvailableDate: maxStartDateOffset,
      region,
      minAvailableDate: currentDate,
    })
  ) {
    openModal('Modal', {
      title: `Oops. Please check ${'\n'}your travel dates.`,
      body: `You can only select start date travel within the next ${maxStartDateOffset} days, sorry about that.`,
      actions: [
        {
          title: 'OK, got it',
          variant: 'primary',
        },
      ],
    });
    return false;
  }

  return true;
};

export const validateTripDuration = ({ durationInDays }: CalendarData) => {
  const openModal = useModalStore.getState().openModal;
  const maxTripDays = getRegionSpecificTripDurationRule();
  const maxTripCalendarDays =
    useConfigStore.getState().regionSpecificConfig?.RULES.MAX_TRIP_CALENDAR_DAYS;

  const maxTripCalendarDaysInMonths = getDurationInMonths({ days: maxTripCalendarDays });

  if (hasTripDatesExceededLimit({ maxTripDays, durationInDays })) {
    openModal('Modal', {
      title: `Oops. Please check ${'\n'}your travel dates.`,
      body: `You can only select travel dates within the next ${maxTripCalendarDaysInMonths} months and can only travel for ${maxTripDays} days per trip, sorry about that.`,
      actions: [
        {
          title: 'OK, got it',
          variant: 'primary',
        },
      ],
    });
    return false;
  }
  return true;
};

export const revertSelectedExtraCancellation = async (get: GetStore<TripStore>) => {
  const { trip, updateBoost, addedSelectedCover } = get();
  const extraCancellationBoost = trip?.boosts?.find(isBoostExtraCancellation);
  const selectedExtraCover = getBoostSelectedExtraCover(extraCancellationBoost);
  const previouslyAddedSelectedCover = addedSelectedCover?.selectedCover ?? 0;
  if (
    !extraCancellationBoost ||
    !selectedExtraCover ||
    !previouslyAddedSelectedCover ||
    previouslyAddedSelectedCover === selectedExtraCover
  ) {
    return;
  }
  await updateBoost({
    boostId: extraCancellationBoost?.boostId,
    isRefreshingTrip: true,
    updatePayload: {
      isAdded: true,
      isSelected: true,
      boostProperty: {
        selectedExtraCover: previouslyAddedSelectedCover,
      },
    },
  });
};
