import get from 'lodash/get';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

import {
  getRegionSelector,
  isAURegionSelector,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import {
  dateToUTCFormat,
  secondaryAgeValidation,
  shouldRevalidateDependencyFlag,
  utcToDateFormat,
  validateDate,
} from 'freely-shared-utils';

import { dobFormat } from '@packages/constants';
import { SecondaryTraveller, TravellersForm } from '@packages/types';

import { Assets } from '@assets';
import { Button } from '@elements/button';
import { i18n } from '@i18n';
import { createNewSecondaryTraveller, openModalSelector, useModalStore } from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

import { Input } from '../input';
import { Text } from '../text';
import { DependencyCheckBox } from './dependencyCheckBox.component';

export const TravellerList = () => {
  const openModal = useModalStore(openModalSelector);
  const region = useRegionStore(getRegionSelector);
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const residentCheckBoxStatus = regionSpecificConfig?.RULES.RESIDENT_CHECKBOX;
  const isAU = useRegionStore(isAURegionSelector);
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<TravellersForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secondaryTravellers',
  });

  const formSecondaryTravellers = getValues('secondaryTravellers') ?? [];
  const maxSecondaryTravellers = regionSpecificConfig?.RULES.MAX_SECONDARY_TRAVELLERS ?? 9;
  const secondaryTravellerDependencyMaxAge =
    regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE?.mid?.max ?? 20;
  const secondaryTravellerDependencyMinAge =
    regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE?.mid?.min ?? 18;

  const formPrimaryTravellerIsResident = getValues('primaryTravellerIsResident');
  // computed state
  const isResidentChecked =
    residentCheckBoxStatus &&
    formPrimaryTravellerIsResident &&
    formSecondaryTravellers.every(it => it.isResident);

  const sendToAnalytics = (traveller: SecondaryTraveller, travellerStatus: boolean) => {
    const eventName = travellerStatus ? 'Trip Traveller Added' : 'Trip Traveller Removed';
    sendAnalyticsEvent(eventName, {
      isDependant: traveller.isDependant,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
    sendToAnalytics(get(formSecondaryTravellers, index), false);
  };

  const addSecondaryTraveller = () => {
    if (formSecondaryTravellers.length >= maxSecondaryTravellers) {
      sendAnalyticsEvent('Modal viewed', {
        modalTitle: i18n.t('travellers.maxTravellersModal.title'),
      });

      return openModal('Modal', {
        titleText: i18n.t('travellers.maxTravellersModal.title'),
        bodyTextProps: {
          children: i18n.t('travellers.maxTravellersModal.content', { maxSecondaryTravellers }),
        },
        actions: [
          {
            title: i18n.t('travellers.maxTravellersModal.actions.ok'),
            variant: 'primary',
          },
        ],
      });
    }
    const newTraveller = createNewSecondaryTraveller({ isResident: isResidentChecked });
    append(newTraveller);
    sendToAnalytics(newTraveller, true);
  };

  return (
    <div className={twMerge('w-full pt-8')}>
      <div
        className={twMerge(
          'border-cabo-200 grid grid-cols-4 flex-col border-t',
          formSecondaryTravellers.length > 0 && 'border-b',
          isAU && 'border-b',
        )}>
        <div className="col-span-3 py-4">
          <Text variant="subTitle-20/sb" className="text-left align-middle">
            {i18n.t('travellers.additionalTravellers.title')}
          </Text>
        </div>
        <div className="py-3 ml-auto">
          <Button
            {...testProps('add-secondary-traveller-button')}
            variant="secondary"
            size="sm"
            onClick={addSecondaryTraveller}
            title={i18n.t('travellers.additionalTravellers.actions.add')}
            iconRight="add"
          />
        </div>
      </div>
      <div className="w-full">
        {fields.map((field, index) => {
          const dobInputId = `secondaryTravellers.${index}.dob` as never;
          const secondaryTravellerErrors = errors.secondaryTravellers?.[index];
          const dobError = secondaryTravellerErrors?.['dob'];
          const residentCheckBoxStatus = !!regionSpecificConfig?.RULES.RESIDENT_CHECKBOX;

          return (
            <div key={field.id}>
              <div className="flex items-center">
                <Text
                  variant={{ sm: 'body-16/sb', lg: 'subTitle-20/sb' }}
                  className="flex-1 py-4 text-left align-top">
                  {i18n.t('travellerForm.additionalTravellers.title', { count: index + 1 })}
                </Text>

                <Button
                  {...testProps(`remove-traveller-button-${index + 1}`)}
                  type="button"
                  onClick={() => handleRemove(index)}
                  variant="tertiary"
                  size="sm"
                  iconLeft="delete"
                  title={i18n.t('travellers.additionalTravellers.actions.remove')}
                />
              </div>
              <div
                className={twMerge(
                  'border-cabo-200 pb-5',
                  index !== formSecondaryTravellers.length - 1 && 'border-b',
                  isAU && 'border-b',
                )}>
                <div className={twMerge(isAU && 'pb-4')}>
                  <Controller
                    rules={{
                      validate: {
                        validDate: v => validateDate(v ?? ''),
                        validateAge: v =>
                          secondaryAgeValidation(v ?? '', {
                            min: 0,
                            max: regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_MAX_AGE,
                            country: region?.country,
                          }),
                      },
                    }}
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        className="text-left"
                        customInput={Input}
                        format={dobFormat}
                        mask="_"
                        isSuccess={field.value && !dobError}
                        hasError={!!dobError}
                        errorMessage={dobError?.message}
                        onChange={e => {
                          const formattedDate = dateToUTCFormat(e.target.value, region?.country);
                          field.onChange(formattedDate);
                          if (
                            shouldRevalidateDependencyFlag(formattedDate, residentCheckBoxStatus)
                          ) {
                            const age = regionDateUtils().getAge(formattedDate);
                            const isYoungAdult =
                              age >= secondaryTravellerDependencyMinAge &&
                              age <= secondaryTravellerDependencyMaxAge;
                            const isChild = age < secondaryTravellerDependencyMinAge;
                            const isAdult = age > secondaryTravellerDependencyMaxAge;
                            if (isYoungAdult) {
                              setValue(`secondaryTravellers.${index}.isDependant`, false);
                              return;
                            }

                            if (isAdult) {
                              setValue(`secondaryTravellers.${index}.isDependant`, false);
                              return;
                            }
                            if (isChild) {
                              setValue(`secondaryTravellers.${index}.isDependant`, false);
                              return;
                            }
                          }
                        }}
                        onBlur={field.onBlur}
                        value={utcToDateFormat(field.value, region?.country)}
                        inputMode="numeric"
                        labelProps={{ children: i18n.t('travellerForm.dobInput.label') }}
                        placeholder={i18n.t('travellerForm.dobInput.placeholder')}
                        svgIcon={
                          <Assets.Calendar
                            className={twMerge('text-gray-400', !!dobError && 'text-red-300')}
                          />
                        }
                      />
                    )}
                    name={dobInputId}
                  />
                </div>
                <DependencyCheckBox index={index} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
