import kebabCase from 'lodash/kebabCase';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { useDestinationsStore } from 'freely-shared-stores';
import { getSearchSuggestions, isSuggestedDestinationSelected } from 'freely-shared-utils';

import { TripDestination } from '@packages/types';

import { Assets } from '@assets';
import { Combobox } from '@headlessui/react';
import { i18n } from '@i18n';
import { testProps } from '@utils';

import { Input } from '../input';
import { Text } from '../text';

export interface SearchDestinationProps {
  onClick: (destination: TripDestination) => void;
  selectedDestinations: TripDestination[];
  onRemoveSelectedDestination: (destination: TripDestination) => void;
  hasReachedMaxDestinations: boolean;
}

export const SearchDestination: React.FC<SearchDestinationProps> = ({
  onClick,
  selectedDestinations,
  onRemoveSelectedDestination,
  hasReachedMaxDestinations,
}) => {
  const tripDestinations = useDestinationsStore(state => state.destinations);

  const [query, setQuery] = useState('');
  const filteredDestinations = getSearchSuggestions(query, tripDestinations ?? []);
  const hasSearchResultFound = filteredDestinations.length > 0;

  return (
    <Combobox>
      <div className="relative">
        <Combobox.Input
          as={Input}
          placeholder={i18n.t('selectDestinations.input.placeholder')}
          svgIcon={
            <Combobox.Button className="absolute inset-y-0 flex items-center rounded-r-md focus:outline-none focus:ring-transparent left-2">
              <Assets.Search className="h-5 w-5 text-fuji-800" aria-hidden="true" />
            </Combobox.Button>
          }
          isDisabled={hasReachedMaxDestinations}
          autoComplete="off"
          className="pl-11 focus:border-nusa-200 focus:ring-nusa-200 placeholder:text-fuji-300 placeholder:text-sm md:placeholder:text-lg py-3 bg-cabo-50/60 w-full rounded-full border-transparent  px-5 shadow-sm focus:outline-none focus:ring-1"
          onChange={event => {
            setQuery(event.target.value);
          }}
        />
        <Combobox.Options className="absolute z-10 border w-full max-h-1/2 rounded-2xl bg-mono-100 p-2 mt-3 shadow-lg">
          {filteredDestinations.map(destination => {
            const isSelected = isSuggestedDestinationSelected(destination, selectedDestinations);
            return (
              <Combobox.Option
                {...testProps(`search-country-destination-${kebabCase(destination.longName)}`)}
                key={destination.longName}
                value={destination}
                onClick={() => onClick(destination)}
                className={({ active }) =>
                  twMerge(
                    'px-2 cursor-pointer hover:bg-nusa-50 hover:rounded-lg py-2',
                    isSelected && 'bg-nusa-50 rounded-lg my-1',
                    active && 'bg-nusa-50 rounded-lg',
                  )
                }>
                <div className={twMerge('flex flex-row items-center text-left')}>
                  <div
                    className={twMerge(
                      'rounded-md h-6 w-8 flex-none',
                      !destination.isCity && 'pt-0.5',
                    )}>
                    {!!destination.countryFlagImage && (
                      <img
                        src={destination.countryFlagImage}
                        className={twMerge('rounded-md w-8 h-5 shadow-md')}
                        alt={destination.countryName}
                      />
                    )}
                  </div>
                  <div className={twMerge('grow pl-4 sm:pl-4')}>
                    <DestinationName destination={destination} searchValue={query} />
                  </div>
                  {isSelected && (
                    <div className={twMerge('pr-3 flex flex-row flex-none items-center flex-end')}>
                      <Text variant="body-16/r" className="text-fuji-800 pt-0.5">
                        Added
                      </Text>
                      <Assets.CloseCircleLight
                        fill={colors.fuji[800]}
                        className="h4 w-4 ml-2 mt-0.5 cursor-pointer"
                        onClick={() => onRemoveSelectedDestination(destination)}
                      />
                    </div>
                  )}
                </div>
              </Combobox.Option>
            );
          })}
          {!hasSearchResultFound && (
            <Combobox.Option
              value=""
              className="px-2 cursor-pointer hover:bg-cabo-50 hover:rounded-lg py-2">
              <Text variant="body-16/r">No Result found.</Text>
            </Combobox.Option>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

const DestinationName = ({
  destination,
  searchValue,
}: {
  destination: TripDestination;
  searchValue: string;
}) => {
  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const matchingString = new RegExp(escapedSearchValue, 'i').exec(destination.longName)?.[0] ?? '';

  return (
    <div className={twMerge('grow')}>
      <Text variant="body-16/r">{destination.isCity && destination.countryName}</Text>
      <Text variant="body-16/r" className="inline">
        {destination.longName.split(matchingString).map((part, index) => {
          if (index / 2 !== 0) {
            return (
              <Text key={`${part}${index}`} variant={'body-16/r'} className="inline">
                <Text variant={'body-16/sb'} className="inline">
                  {new RegExp(escapedSearchValue, 'i').exec(destination.longName)?.[0] ?? ''}
                </Text>
                {part}
              </Text>
            );
          }
          return (
            <Text key={`${part}${index}`} variant={'body-16/r'} className="inline">
              {part}
            </Text>
          );
        })}
      </Text>
    </div>
  );
};
