import { StateCreator } from 'zustand';

import { calcGroupType, filterTravellersByDependent } from 'freely-shared-utils';

import {
  AutoDiscountCode,
  CreateQuoteRequest,
  ParticipantPayload,
  PromotionCode,
  RegionCode,
  SecondaryTraveller,
  Trip,
  TripDestination,
} from '../../../../types/src';
import { FeatureFlagsArgs, getFeatureFlags } from './featureFlags.api';

type PostHogFeatureFlagPayload<T> = {
  default?: T;
  mobile?: FeatureFlagsPayload<T>;
  web?: FeatureFlagsPayload<T>;
  qw?: FeatureFlagsPayload<T>;
};

type FeatureFlagsPayload<T> = {
  default?: T;
  AU?: T;
  US?: T;
};

export type MotorCycleUpsellPayload = {
  primaryTravellerAge?: {
    max: number;
    min: number;
  };
  destinations?: (TripDestination & { title: string; description: string })[];
  defaultText?: {
    title: string;
    description: string;
  };
};

/**
 * DISCOUNT CODE CONFIG PAYLOAD
 */
export type AutoDiscountCodePayload = AutoDiscountCode[];

export type DiscountCodeInputPayload = {
  pages?: {
    [key: string]: boolean;
  };
};

export type CruiseCoverNotificationPayload = {
  title: string;
  subtitle: string;
  cruiseCountryCodes: string[];
};

export type ReferralPayload = {
  hostUrl: string;
};

export type FeatureFlagsPayloads = {
  'motorcycle-boost-upsell'?: MotorCycleUpsellPayload;
  'abandon-cart'?: undefined;
  'email-quote'?: undefined;
  'discount-code-input'?: DiscountCodeInputPayload;
  'auto-discount'?: AutoDiscountCodePayload;
  'email-quote-modal-cs-number'?: { csNumberEnabled: boolean };
  'cruise-cover-notification'?: CruiseCoverNotificationPayload;
  emc?: undefined;
  referral?: ReferralPayload;
};

export type FeatureFlagsVariants = {
  'motorcycle-boost-upsell'?: undefined;
  'abandon-cart'?: undefined;
  'email-quote'?: undefined;
  'discount-code-input'?: undefined;
  'auto-discount'?: undefined;
  'email-quote-modal-cs-number'?: 'with-cs-number' | 'without-cs-number';
  'cruise-cover-notification'?: undefined;
  referral?: undefined;
  emc?: undefined;
};

export type FeatureFlagsState = {
  featureFlags?: {
    [key in keyof FeatureFlagsPayloads]: {
      isEnabled?: boolean;
      payload?: FeatureFlagsPayloads[key];
      value?: FeatureFlagsVariants[key];
    };
  };
  conditions?: {
    region?: RegionCode;
    platform?: 'mobile' | 'web' | 'qw';
  };
};

export type FeatureFlagsActions = {
  initFeatureFlags: (args: FeatureFlagsArgs) => Promise<FeatureFlagsState['featureFlags']>;
  getAutoDiscount: (props: {
    secondaryTravellers?: SecondaryTraveller[];
    appliedDiscountCode: string;
    destinations?: TripDestination[];
    referralData?: ParticipantPayload | null;
  }) => string | null;
  getGroupDiscountPayload: (discountCode: string) => AutoDiscountCode | null;
  getDestinationDiscountPayload: (discountCode: string) => AutoDiscountCode | null;
  getReferralDiscountPayload: (discountCode: string) => AutoDiscountCode | null;
};

const initialState: FeatureFlagsState = {
  featureFlags: {
    'motorcycle-boost-upsell': undefined,
    'abandon-cart': undefined,
    'discount-code-input': undefined,
    'email-quote': undefined,
    'auto-discount': undefined,
    'email-quote-modal-cs-number': undefined,
    'cruise-cover-notification': undefined,
    referral: undefined,
  },
};

export type FeatureFlagsStore = FeatureFlagsState & FeatureFlagsActions;

export const REMOVE_GROUP_DISCOUNT = 'removeDiscount';

export const featureFlagsStore: StateCreator<FeatureFlagsStore, [['zustand/immer', never]], []> = (
  set,
  get,
) => ({
  ...initialState,
  initFeatureFlags: async args => {
    const response = await getFeatureFlags(args);
    set(state => {
      if (state.conditions) {
        state.conditions.platform = args.platform;
        state.conditions.region = args.region;
      }
      const flags = Object.keys(response?.featureFlags || {});

      flags.forEach(flag => {
        const isEnabled = !!response?.featureFlags?.[flag] as boolean;
        const featureFlagPayloadsKeys = Object.keys(response?.featureFlagPayloads || {});

        const featureFlag = {
          isEnabled: isEnabled,
          value: response?.featureFlags?.[flag],
          payload: featureFlagPayloadsKeys.includes(flag)
            ? getConditionSpecificPayload(
                state.conditions,
                JSON.parse(response?.featureFlagPayloads?.[flag] ?? ''),
              )
            : undefined,
        };
        (state.featureFlags as any)[flag] = featureFlag;
      });
      state?.featureFlags;
    });
    return get().featureFlags;
  },
  getAutoDiscount: ({ secondaryTravellers, appliedDiscountCode, destinations, referralData }) => {
    const regionCode = get()?.conditions?.region;
    const isReferralEnabled = !!get()?.featureFlags?.referral?.isEnabled;
    const isAutoDiscountEnabled = !!get()?.featureFlags?.['auto-discount']?.isEnabled;
    const autoDiscountPayload = get()?.featureFlags?.['auto-discount']?.payload?.filter(
      i => i?.isEnabled,
    );

    const hasAppliedNonAutoDiscountCode =
      !!appliedDiscountCode &&
      !autoDiscountPayload?.find(i => i.discountCode === appliedDiscountCode);

    if (!secondaryTravellers || !isAutoDiscountEnabled || hasAppliedNonAutoDiscountCode) {
      return null;
    }

    const numberOfAdultTravellers =
      filterTravellersByDependent({
        travellers: secondaryTravellers,
        type: 'adults',
        defaultMaxDependantAge: 18,
        region: { country: regionCode ?? 'AU' },
      }).length + 1; // primary traveller is always an adult +1

    const groupType = calcGroupType(numberOfAdultTravellers);

    const selectedCountryCodes = destinations?.map(i => i.countryCode) ?? [];

    let hasMatchingCountryCode = false;

    const possibleDiscountCodes = autoDiscountPayload?.filter(i => {
      if (i.rules?.selectedCountryCodes && i.rules?.selectedCountryCodes.length > 0) {
        hasMatchingCountryCode = i.rules?.selectedCountryCodes?.some(i =>
          selectedCountryCodes?.includes(i),
        );
        return hasMatchingCountryCode;
      }
      if (i.type === groupType) {
        return true;
      }

      if (i?.type === 'referralDiscount' && referralData?.id && isReferralEnabled) {
        return (referralData?.referralCount ?? 0) <= (i?.rules?.maxReferralCount ?? 0);
      }
      return false;
    });

    possibleDiscountCodes?.sort((a, b) => {
      if (a.type === 'referralDiscount') {
        return -1;
      }
      if (b.type === 'referralDiscount') {
        return 1;
      }

      return b.discountAmount - a.discountAmount;
    });

    const isAppliedDiscountCodeReferral = possibleDiscountCodes?.some(
      it => it?.type === 'referralDiscount' && it?.discountCode === appliedDiscountCode,
    );

    if (
      !isAppliedDiscountCodeReferral &&
      numberOfAdultTravellers === 1 &&
      appliedDiscountCode &&
      !hasMatchingCountryCode &&
      !possibleDiscountCodes?.length
    ) {
      return REMOVE_GROUP_DISCOUNT;
    }

    if (possibleDiscountCodes && possibleDiscountCodes?.length > 0) {
      return possibleDiscountCodes?.[0]?.discountCode ?? null;
    }

    return null;
  },
  getGroupDiscountPayload: (discountCode: string) => {
    const featureFlag = get()?.featureFlags;
    const isEnabled = featureFlag?.['auto-discount']?.isEnabled;
    return (
      featureFlag?.['auto-discount']?.payload?.find(
        it =>
          ['group', 'couple'].includes(it.type) &&
          isEnabled &&
          it.isEnabled &&
          it.discountCode === discountCode,
      ) ?? null
    );
  },
  getDestinationDiscountPayload: (discountCode: string) => {
    const featureFlag = get()?.featureFlags;
    const isEnabled = featureFlag?.['auto-discount']?.isEnabled;
    return (
      featureFlag?.['auto-discount']?.payload?.find(
        it =>
          ['discountedDestination'].includes(it.type) &&
          isEnabled &&
          it.isEnabled &&
          it.discountCode === discountCode,
      ) ?? null
    );
  },
  getReferralDiscountPayload: (discountCode: string) => {
    const featureFlag = get()?.featureFlags;
    const isEnabled = featureFlag?.['auto-discount']?.isEnabled;
    return (
      featureFlag?.['auto-discount']?.payload?.find(
        it =>
          it?.type === 'referralDiscount' &&
          isEnabled &&
          it.isEnabled &&
          it.discountCode === discountCode,
      ) ?? null
    );
  },
});

type ConditionArgs = FeatureFlagsState['conditions'];
type PayloadType = PostHogFeatureFlagPayload<FeatureFlagsPayloads[keyof FeatureFlagsPayloads]>;

const getConditionSpecificPayload = (
  args: ConditionArgs,
  payload?: PayloadType,
): FeatureFlagsPayloads[keyof FeatureFlagsPayloads] | undefined => {
  const defaultPayload = payload?.default;

  if (defaultPayload && Object.values(defaultPayload).length > 0) {
    return defaultPayload;
  }

  if (!args?.platform) return undefined;
  const platformPayload = payload?.[args?.platform]?.default;

  if (args?.platform && platformPayload && Object.values(platformPayload).length > 0) {
    return platformPayload;
  }

  if (!args?.region) return undefined;
  const regionPayload = payload?.[args?.platform]?.[args?.region];

  if (args?.region) {
    return regionPayload;
  }

  return undefined;
};

export function shouldApplyGroupDiscount(groupDiscount?: string | null) {
  return !!groupDiscount && groupDiscount !== REMOVE_GROUP_DISCOUNT;
}

export function shouldRemoveGroupDiscount(groupDiscount: string | null) {
  return groupDiscount === REMOVE_GROUP_DISCOUNT;
}

export function applyAutoDiscount<T extends Trip | Partial<Trip> | CreateQuoteRequest>(
  groupDiscount: string | null,
  trip: T,
): T {
  if (shouldApplyGroupDiscount(groupDiscount)) {
    const productId = (trip as Trip)?.promotionCode?.productId ?? trip?.productId ?? '';
    return {
      ...trip,
      productId,
      promotionCode: {
        discountCode: groupDiscount,
      } as PromotionCode,
    };
  }
  return trip;
}
