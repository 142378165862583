import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

import { getRegionSelector, useConfigStore, useRegionStore } from 'freely-shared-stores';
import {
  dateToUTCFormat,
  primaryAgeValidation,
  utcToDateFormat,
  validateDate,
} from 'freely-shared-utils';

import { dobFormat } from '@packages/constants';
import { SecondaryTraveller, TravellersForm } from '@packages/types';

import { Assets } from '@assets';
import { usePriceChangeModal } from '@hooks';
import { i18n } from '@i18n';
import { useGuestStore, useTripStore } from '@store';
import { sendAnalyticsEvent, shouldUpdateTrip } from '@utils';

import { Container } from '../container';
import { FooterButtonGroup } from '../footerButtonGroup';
import { GetQuoteProvider } from '../getQuoteProvider';
import { Input } from '../input';
import { Text } from '../text';
import { ResidentStatus } from './residentStatus.component';
import { TravellerList } from './travellerList.component';

// utility function for comparing array of secondary travellers
const deepComparison = (a: SecondaryTraveller[], b: SecondaryTraveller[]) => {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((aItem, index) => {
    const bItem: SecondaryTraveller = b[index];
    return isEqual(aItem, bItem);
  });
};
export interface EditTravellersProps {
  onNext: () => Promise<void> | void;
  onBack: () => void;
  isModal?: boolean;
}

export const EditTravellers = (props: EditTravellersProps) => {
  const guestDetails = useGuestStore(state => state.guest);
  const primaryTravellerDOB = useTripStore(state => state.trip?.primaryTraveller?.dob ?? '');
  const secondaryTravellers = useTripStore(state => state.trip?.secondaryTravellers);
  const primaryTravellerIsResident = useTripStore(
    state => !!state.trip?.primaryTraveller?.isResident,
  );
  const residentCheckBoxStatus = useConfigStore(
    state => state?.regionSpecificConfig?.RULES.RESIDENT_CHECKBOX,
  );

  const defaultValues = {
    primaryTravellerDOB,
    secondaryTravellers: secondaryTravellers?.map(traveller => {
      const guestSecondaryTravellerDetails = guestDetails?.secondaryTravellers?.find(
        st => st.sortKey === traveller.sortKey,
      );
      return { ...traveller, ...guestSecondaryTravellerDetails, dob: traveller.dob ?? '' };
    }),
    ...(residentCheckBoxStatus && { primaryTravellerIsResident }),
  };

  return (
    <GetQuoteProvider defaultFormValues={defaultValues} mode="all" reValidateMode="onChange">
      <EditTravellersContent {...props} />
    </GetQuoteProvider>
  );
};

export const EditTravellersContent = ({ onNext, onBack, isModal = false }: EditTravellersProps) => {
  // global state
  const region = useRegionStore(getRegionSelector);
  const setTripPrimaryTraveller = useTripStore(state => state.setPrimaryTraveller);
  const setTripSecondaryTravellers = useTripStore(state => state.setSecondaryTravellers);
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const syncTrip = useTripStore(state => state.syncTrip);
  const { openPriceChangeModal } = usePriceChangeModal();
  const residentCheckBoxStatus = regionSpecificConfig?.RULES.RESIDENT_CHECKBOX;
  const tripStorePrimaryTraveller = useTripStore(state => state.trip?.primaryTraveller);
  const tripStoreSecondaryTravellers = useTripStore(state => state.trip?.secondaryTravellers);
  const setGuest = useGuestStore(state => state.setGuest);
  const guestDetails = useGuestStore(state => state.guest);
  const regionSpecificButtonLabel = i18n.t(
    region?.country === 'US' ? 'global.actions.next' : 'travellers.actions.quote',
  );

  useEffect(() => {
    if (isModal) {
      sendAnalyticsEvent('Modal viewed', { modalTitle: i18n.t('travellers.modal.title') });
    }
  }, [isModal]);

  /**
   * React hook form
   */

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext<TravellersForm>();

  const formPrimaryTravellerDOB = getValues('primaryTravellerDOB');
  const formPrimaryTravellerIsResident = watch('primaryTravellerIsResident');
  const formSecondaryTravellers = watch('secondaryTravellers') ?? [];
  const titleId = isModal ? i18n.t('travellers.modal.title') : i18n.t('travellers.title');

  const hasDifferentPrimaryTraveller = formPrimaryTravellerDOB !== tripStorePrimaryTraveller?.dob;

  const hasDifferentSecondaryTravellers = !deepComparison(
    tripStoreSecondaryTravellers,
    formSecondaryTravellers,
  );

  const canEdit = hasDifferentPrimaryTraveller || hasDifferentSecondaryTravellers;

  const isNextButtonDisabled = isModal ? !isValid || !canEdit : !isValid;

  const handleBackButton = () => {
    if (residentCheckBoxStatus && !formPrimaryTravellerIsResident && tripStorePrimaryTraveller) {
      setTripPrimaryTraveller({ ...tripStorePrimaryTraveller, isResident: false });
    }
    onBack();
  };

  const editTravellers = (values: TravellersForm) =>
    openPriceChangeModal({
      onNext: async () => {
        setTripPrimaryTraveller({
          firstName: '',
          lastName: '',
          dob: values.primaryTravellerDOB,
          isResident: values.primaryTravellerIsResident,
        });
        setTripSecondaryTravellers(values?.secondaryTravellers ?? []);
        onNext();
        const updatedTrip = await syncTrip();
        const shouldUpdateGuest =
          guestDetails &&
          updatedTrip &&
          Object.keys(shouldUpdateTrip({ guestDetails, trip: updatedTrip })).length > 0;
        if (shouldUpdateGuest) {
          // Scenario of updating guest details, when the user edits the travellers on the modal
          const secondaryTravellers = updatedTrip?.secondaryTravellers;
          setGuest({ secondaryTravellers });
        }
      },
    });

  const onSubmit = async (values: TravellersForm) => {
    if (isModal) {
      return editTravellers(values);
    } else {
      const primaryTraveller = {
        firstName: '',
        lastName: '',
        dob: values.primaryTravellerDOB,
        ...(residentCheckBoxStatus && { isResident: values.primaryTravellerIsResident }),
      };
      setTripPrimaryTraveller(primaryTraveller);
      setTripSecondaryTravellers(values?.secondaryTravellers ?? []);
      await onNext();
    }
  };

  return (
    <Container size="xl" titleText={titleId}>
      <form>
        <div className="flex flex-col">
          {/* primary traveller */}
          <Text variant="subTitle-20/sb" className="flex py-5 text-left">
            {i18n.t('travellerForm.primaryTraveller.title')}
          </Text>
          <Controller
            control={control}
            rules={{
              validate: {
                validDate: v => validateDate(v ?? ''),
                validateAge: v =>
                  primaryAgeValidation(v ?? '', {
                    min: regionSpecificConfig?.RULES.PRIMARY_TRAVELLER_AGE.min,
                    max: regionSpecificConfig?.RULES.PRIMARY_TRAVELLER_AGE.max,
                    country: region?.country,
                  }),
              },
            }}
            render={({ field }) => (
              <PatternFormat
                value={utcToDateFormat(field.value, region?.country)}
                format={dobFormat}
                mask="_"
                customInput={Input}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  (e.key === 'Enter' && e.preventDefault()) ||
                    (e.key === '13' && e.preventDefault());
                }}
                isSuccess={!!field.value && !errors.primaryTravellerDOB}
                onChange={(e: { target: { value: string } }) =>
                  field.onChange(dateToUTCFormat(e.target.value, region?.country))
                }
                inputMode="numeric"
                onBlur={field.onBlur}
                labelProps={{ children: i18n.t('travellerForm.dobInput.label') }}
                placeholder={i18n.t('travellerForm.dobInput.placeholder')}
                svgIcon={
                  <Assets.Calendar
                    className={twMerge(
                      'text-gray-400',
                      !!errors.primaryTravellerDOB && 'text-red-300',
                    )}
                  />
                }
                errorMessage={errors?.primaryTravellerDOB?.message}
                hasError={!!errors.primaryTravellerDOB}
              />
            )}
            name="primaryTravellerDOB"
          />

          <TravellerList />
          {residentCheckBoxStatus && <ResidentStatus />}
        </div>
        <hr className="border-cabo-200 mb-6" />
        <div className={twMerge('flex flex-col gap-4', isModal && 'flex-col md:flex-row-reverse')}>
          <FooterButtonGroup
            nextButtonProps={{
              type: 'submit',
              variant: 'primary',
              title: isModal ? i18n.t('travellers.modal.actions.save') : regionSpecificButtonLabel,
              onClick: handleSubmit(onSubmit),
              disabled: isNextButtonDisabled,
              className: 'w-full',
            }}
            backButtonProps={{
              variant: 'secondary',
              title: isModal ? i18n.t('global.actions.cancel') : i18n.t('global.actions.back'),
              onClick: handleBackButton,
              className: 'w-full',
            }}
          />
        </div>
      </form>
    </Container>
  );
};
