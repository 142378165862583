import { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { Text } from '@components';

import { Icon, IconName } from '../icon';
import { Loader } from '../loader';

export type ButtonVariant = 'feature' | 'primary' | 'secondary' | 'tertiary' | 'destructive';
export type ButtonSize = 'sm' | 'md';

export type ButtonProps = {
  variant: ButtonVariant;
  size?: ButtonSize;
  iconLeft?: IconName;
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  iconRight?: IconName;
  icon?: IconName;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

export const buttonVariants = tv({
  base: 'relative flex gap-s4 items-center duration-300 transition-all rounded-full focus:ring-0 disabled:text-disabled disabled:bg-surface-disabled justify-center',
  variants: {
    variant: {
      feature:
        'text-action bg-surface-action-feature-default hover:bg-surface-action-feature-hover active:bg-surface-action-feature-active',
      primary:
        'text-action-dark-surface hover:text-action active:text-action bg-surface-action-primary-default hover:bg-surface-action-primary-hover active:bg-surface-action-primary-active',
      secondary:
        'text-action ring-2 ring-border-action-default hover:ring-border-action-hover active:ring-border-action-active focus:ring-2 hover:focus:ring-0 hover:ring-0 focus:ring-border-action-default  ring-inset  bg-transparent hover:bg-surface-action-secondary-hover active:bg-surface-action-secondary-active disabled:bg-transparent disabled:ring-border-disable',
      tertiary:
        'bg-transparent hover:bg-surface-action-tertiary-hover active:bg-surface-action-tertiary-active text-link-default disabled:bg-transparent ',
      destructive:
        'text-error bg-surface-action-destructive-default hover:bg-surface-action-destructive-hover active:bg-surface-action-destructive-active',
    },
    size: {
      sm: 'px-s12 py-s4',
      md: 'px-s24 py-s12',
      'icon-sm': 'p-s4',
      'icon-md': 'p-s12',
    },
  },
});

const buttonLoaderVariants = tv({
  base: 'absolute text-icon-disabled',
  variants: {
    size: {
      sm: 'h-s16 left-[calc(50%-0.5rem)]',
      md: 'h-s20 left-[calc(50%-0.625rem)]',
    },
  },
});

export const Button = ({
  variant = 'primary',
  className,
  iconLeft,
  iconRight,
  disabled,
  isLoading,
  icon,
  title,
  type = 'button',
  size = 'md',
  ...rest
}: ButtonProps) => {
  const loadingClassNames = twMerge(isLoading && 'text-transparent');
  const hasIcon = icon || iconLeft || iconRight;

  const customIconStyle = hasIcon && loadingClassNames;

  const CenteredIcon = icon && <Icon size={size} className={customIconStyle} name={icon} />;

  const IconAndText = !icon && (
    <>
      <Icon size={size} className={customIconStyle} name={iconLeft} />
      <Text className={loadingClassNames} variant={'body-16/sb'}>
        {title}
      </Text>
      <Icon size={size} className={customIconStyle} name={iconRight} />
    </>
  );

  const ButtonLoader = isLoading && <Loader className={buttonLoaderVariants({ size })} />;

  return (
    <button
      disabled={disabled || isLoading}
      className={buttonVariants({ variant, size: icon ? `icon-${size}` : size, className })}
      type={type}
      {...rest}>
      {CenteredIcon}
      {IconAndText}
      {ButtonLoader}
    </button>
  );
};
