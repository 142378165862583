import { ChangeEventHandler, FC, LegacyRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';

import { Assets } from '@assets';
import { testProps } from '@utils';

import { FontVariant, ResponsiveFontVariant, Text, TextProps } from '../text';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isDisabled?: boolean;
  svgIcon?: React.ReactNode;
  isSuccess?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  errorMessageTextVariant?: FontVariant | ResponsiveFontVariant;
  errorMessageClassName?: string;
  onErrorIconClick?: () => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  inputWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
  labelProps?: TextProps;
  svgIconRight?: React.ReactNode;
  rightLabelProps?: TextProps;
  svgRightIconClassName?: string;
  outline?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  testPropID?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  showErrorIcon?: boolean;
}

const InputRightIcon: FC<
  Pick<
    InputProps,
    | 'isDisabled'
    | 'isSuccess'
    | 'hasError'
    | 'svgIconRight'
    | 'onErrorIconClick'
    | 'svgRightIconClassName'
    | 'showErrorIcon'
  >
> = ({
  isDisabled,
  isSuccess,
  hasError,
  svgIconRight,
  onErrorIconClick,
  svgRightIconClassName,
  showErrorIcon,
}) => {
  if (isDisabled) {
    return null;
  }

  let RightIcon = svgIconRight;

  if (showErrorIcon) {
    RightIcon = svgIconRight;
  } else if (isSuccess) {
    RightIcon = <Assets.CheckGreen />;
  } else if (hasError) {
    RightIcon = (
      <Assets.CloseCircleLight
        className="cursor-pointer"
        onClick={onErrorIconClick}
        fill={colors.red[500]}
      />
    );
  }

  return (
    <span
      className={twMerge(
        'absolute top-1/2 right-4 flex h-0 items-center pl-2',
        '[&>svg]:h-5 [&>svg]:w-5',
        svgRightIconClassName,
      )}>
      {RightIcon}
    </span>
  );
};

export const Input = forwardRef(
  (
    {
      svgIcon,
      isSuccess,
      hasError,
      isDisabled,
      errorMessage,
      errorMessageTextVariant = 'subHeading-14/r',
      onErrorIconClick,
      className,
      containerProps,
      labelProps,
      svgIconRight,
      outline,
      rightLabelProps,
      inputWrapperProps,
      svgRightIconClassName,
      errorMessageClassName,
      showErrorIcon,
      ...rest
    }: InputProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    const commonProps = {
      ...rest,
      className: twMerge(
        `placeholder:text-fuji-300 w-full rounded-full border-[1px] border-transparent py-3 focus:outline-0 focus:border-transparent focus:ring-1 focus:border-nusa-200 focus:ring-nusa-200 autofill:transition-none`,
        !isDisabled &&
          (hasError || showErrorIcon) &&
          ' text-red-500  focus:ring-red-500 focus:border-red-500',
        svgIcon ? '!px-10' : '!px-7',
        (showErrorIcon || isSuccess || hasError) && '!pr-10',
        'bg-cabo-50/60',
        outline && '!bg-mono-100 border-fuji-50/40 ',
        outline &&
          (hasError || showErrorIcon) &&
          '!border-red-500 bg-transparent focus:border-red-500 mb-0 focus:ring-0',
        className,
      ),
      disabled: isDisabled,
    };

    return (
      <div {...containerProps}>
        <div className="flex items-center justify-between">
          {labelProps && (
            <Text
              {...labelProps}
              variant={labelProps?.variant ?? 'body-16/r'}
              className={twMerge('mb-1 inline-block', labelProps?.className)}
            />
          )}
          {rightLabelProps && (
            <Text
              {...rightLabelProps}
              variant={rightLabelProps?.variant ?? 'body-16/r'}
              className={twMerge('mb-1 inline-block', rightLabelProps?.className)}
            />
          )}
        </div>

        <div
          {...inputWrapperProps}
          className={twMerge('relative block', inputWrapperProps?.className)}>
          {svgIcon && (
            <span className="absolute top-[47%] left-2 flex h-0 items-center  px-2   [&>svg]:h-4 [&>svg]:w-4">
              {svgIcon}
            </span>
          )}

          <input {...commonProps} ref={ref} />

          <InputRightIcon
            hasError={hasError}
            isDisabled={isDisabled}
            isSuccess={isSuccess}
            onErrorIconClick={onErrorIconClick}
            svgIconRight={svgIconRight}
            svgRightIconClassName={svgRightIconClassName}
            showErrorIcon={showErrorIcon}
          />
        </div>
        {(hasError || showErrorIcon) && (
          <Text
            {...testProps('input-error-message')}
            variant={errorMessageTextVariant}
            className={twMerge('text-red-500 mt-0.5', errorMessageClassName)}>
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
