import { colors } from 'freely-shared-design';

import { FreelyPaymentMethod, PAYMENT_TYPE, RegionCode, RegionData, State } from '../../types/src';

export const MIN_VALID_CREDIT_LENGTH = 13;

export const FREELY_WEB_DEFAULT_PAYMENTS: FreelyPaymentMethod[] = ['Mastercard', 'Visa', 'Amex'];

export const DEFAULT_EXCESS_DESCRIPTION_CLAIM_AMOUNT = 600;
export const DEFAULT_MAX_CALENDAR_DAYS = 730; // 24 months in days

export const DEFAULT_MAX_TRIP_DAYS = 548;
export const DEFAULT_MAX_TRIP_DAYS_EMC = 365;

export const EXTRA_CANCELLATION_UNLIMITED_VALUE = 40000;
export const DEFAULT_DOB_PLACEHOLDER = 'DD/MM/YYYY';
export const DEFAULT_MAX_SECONDARY_TRAVELLERS = 9;
export const DATE_MASK = '[00]{/}[00]{/}[0000]';

export enum dobPlaceholder {
  AU = 'Date of Birth (DD/MM/YYYY)',
  US = 'Date of Birth (MM/DD/YYYY)',
}

export const FREELY_SWITCH_LOTTIE_SPEED = 2.5;
export const DEFAULT_MIN_TRIP_COST_PER_PERSON = 1;
export const DEFAULT_MAX_TRIP_COST_PER_PERSON = 20000;
export const MAX_APP_MESSAGE_BODY_WIDTH = '71.33%';

export const MAX_APP_RISK_ALERT_MESSAGE_BODY_WIDTH = '90%';
export const RISK_ALERT_MESSAGE_BORDER_RADIUS = 20;

export const COUNTRY_CALLING_CODE: Record<RegionCode, string> = {
  AU: '+61',
  US: '+1',
};

interface AgeBandRange {
  min: number;
  max: number;
  band: string;
}
export const AGE_BAND_RANGES: AgeBandRange[] = [
  { min: 0, max: 24, band: '0-24' },
  { min: 25, max: 44, band: '25-44' },
  { min: 45, max: 49, band: '45-49' },
  { min: 50, max: 59, band: '50-59' },
  { min: 60, max: 64, band: '60-64' },
  { min: 65, max: 69, band: '65-69' },
  { min: 70, max: 72, band: '70-72' },
  { min: 73, max: 74, band: '73-74' },
  { min: 75, max: 76, band: '75-76' },
  { min: 77, max: 79, band: '77-79' },
  { min: 80, max: 81, band: '80-81' },
  { min: 82, max: 84, band: '82-84' },
  { min: 85, max: 89, band: '85-89' },
  { min: 90, max: 99, band: '90-99' },
];

export const AU_STATES: Array<State> = [
  { name: 'New South Wales', code: 'NSW' },
  { name: 'Queensland', code: 'QLD' },
  { name: 'Victoria', code: 'VIC' },
  { name: 'South Australia', code: 'SA' },
  { name: 'Tasmania', code: 'TAS' },
  { name: 'Western Australia', code: 'WA' },
  { name: 'Australian Capital Territory', code: 'ACT' },
  { name: 'Northern Territory', code: 'NT' },
];

export const US_STATES: Array<State> = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District of Columbia', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];
export const REGIONS: Record<RegionCode, RegionData> = {
  AU: {
    name: 'Australia',
    code: 'AU',
    states: AU_STATES,
  },
  US: {
    name: 'USA',
    code: 'US',
    states: US_STATES,
  },
};

export const RISK_COLORS = {
  EXTREME: colors.red[500],
  HIGH: colors.orange[500],
  MEDIUM: colors.orange[300],
  MODERATE: colors.green[500],
  LOW: colors.green[300],
} as const;

export const dobFormat = '##/##/####';
export const mmYYFormat = '##/##';

export const PHOTO_NOTE_MIN_CHARACTERS = 2;
export const PHOTO_NOTE_MAX_CHARACTERS = 30;
export const WHAT_HAPPENED_NOTE_MIN_CHARACTERS = 1;
export const WHAT_HAPPENED_NOTE_MAX_CHARACTERS = 500;

export const MAX_EVIDENCE_FILES_SIZE = 25000000; // 25 Megabytes

/* Define the credit card number ranges and lengths.
  The ranges represent the potentially starting characters of the credit card and the length represent the different lengths it could be.
  A credit card can have various lengths. It is best we maintain the credit cards ranges and length rather than use 3rd party packages since most packages do not
  keep up to date with the constant changes and have not been updated the last few years.
  Please read this post on stack overflow on why we should avoid using regex for credit cards https://stackoverflow.com/a/55019607

  Ranges and Lengths come from https://en.wikipedia.org/wiki/Payment_card_number
 */
export const CARD_NUMBERS: Partial<Record<PAYMENT_TYPE, { ranges: string[]; lengths: number[] }>> =
  {
    [PAYMENT_TYPE.AMEX]: {
      ranges: ['34', '37'],
      lengths: [15],
    },
    [PAYMENT_TYPE.DISCOVER]: {
      ranges: [
        '6011',
        '644',
        '645',
        '646',
        '647',
        '648',
        '649',
        '65',
        '622126',
        '622127',
        '622128',
        '622129',
        '622130',
        '622131',
        '622132',
        '622133',
        '622134',
        '622135',
        '622136',
        '622137',
        '622138',
        '622139',
        '622140',
        '622141',
        '622142',
        '622143',
        '622144',
        '622145',
        '622146',
        '622147',
        '622148',
        '622149',
        '622150',
        '622151',
        '622152',
        '622153',
        '622154',
        '622155',
        '622156',
        '622157',
        '622158',
        '622159',
        '622160',
        '622161',
        '622162',
        '622163',
        '622164',
        '622165',
        '622166',
        '622167',
        '622168',
        '622169',
        '622170',
        '622171',
        '622172',
        '622173',
        '622174',
        '622175',
        '622176',
        '622177',
        '622178',
        '622179',
        '622180',
        '622181',
        '622182',
        '622183',
        '622184',
        '622185',
        '622186',
        '622187',
        '622188',
        '622189',
        '622190',
        '622191',
        '622192',
        '622193',
        '622194',
        '622195',
        '622196',
        '622197',
        '622198',
        '622199',
        '622200',
        '622201',
        '622202',
        '622203',
        '622204',
        '622205',
        '622206',
        '622207',
        '622208',
        '622209',
        '622210',
        '622211',
        '622212',
        '622213',
        '622214',
        '622215',
        '622216',
        '622217',
        '622218',
        '622219',
        '622220',
        '622221',
        '622222',
        '622223',
        '622224',
        '622225',
        '622226',
        '622227',
        '622228',
        '622229',
        '622230',
        '622231',
        '622232',
        '622233',
        '622234',
        '622235',
        '622236',
        '622237',
        '622238',
        '622239',
        '622240',
        '622241',
        '622242',
        '622243',
        '622244',
        '622245',
        '622246',
        '622247',
        '622248',
        '622249',
        '622250',
        '622251',
        '622252',
        '622253',
        '622254',
        '622255',
        '622256',
        '622257',
        '622258',
        '622259',
        '622260',
        '622261',
        '622262',
        '622263',
        '622264',
        '622265',
        '622266',
        '622267',
        '622268',
        '622269',
        '622270',
        '622271',
        '622272',
        '622273',
        '622274',
        '622275',
        '622276',
        '622277',
        '622278',
        '622279',
        '622280',
        '622281',
        '622282',
        '622283',
        '622284',
        '622285',
        '622286',
        '622287',
        '622288',
        '622289',
        '622290',
        '622291',
        '622292',
        '622293',
        '622294',
        '622295',
        '622296',
        '622297',
        '622298',
        '622299',
        '622300',
        '622301',
        '622302',
        '622303',
        '622304',
        '622305',
        '622306',
        '622307',
        '622308',
        '622309',
        '622310',
        '622311',
        '622312',
        '622313',
        '622314',
        '622315',
        '622316',
        '622317',
        '622318',
        '622319',
        '622320',
        '622321',
        '622322',
        '622323',
        '622324',
        '622325',
        '622326',
        '622327',
        '622328',
        '622329',
        '622330',
        '622331',
        '622332',
        '622333',
        '622334',
        '622335',
        '622336',
        '622337',
        '622338',
        '622339',
        '622340',
        '622341',
        '622342',
        '622343',
        '622344',
        '622345',
        '622346',
        '622347',
        '622348',
        '622349',
        '622350',
        '622351',
        '622352',
        '622353',
        '622354',
        '622355',
        '622356',
        '622357',
        '622358',
        '622359',
        '622360',
        '622361',
        '622362',
        '622363',
        '622364',
        '622365',
        '622366',
        '622367',
        '622368',
        '622369',
        '622370',
        '622371',
        '622372',
        '622373',
        '622374',
        '622375',
        '622376',
        '622377',
        '622378',
        '622379',
        '622380',
        '622381',
        '622382',
        '622383',
        '622384',
        '622385',
        '622386',
        '622387',
        '622388',
        '622389',
        '622390',
        '622391',
        '622392',
        '622393',
        '622394',
        '622395',
        '622396',
        '622397',
        '622398',
        '622399',
        '622400',
        '622401',
        '622402',
        '622403',
        '622404',
        '622405',
        '622406',
        '622407',
        '622408',
        '622409',
        '622410',
        '622411',
        '622412',
        '622413',
        '622414',
        '622415',
        '622416',
        '622417',
        '622418',
        '622419',
        '622420',
        '622421',
        '622422',
        '622423',
        '622424',
        '622425',
        '622426',
        '622427',
        '622428',
        '622429',
        '622430',
        '622431',
        '622432',
        '622433',
        '622434',
        '622435',
        '622436',
        '622437',
        '622438',
        '622439',
        '622440',
        '622441',
        '622442',
        '622443',
        '622444',
        '622445',
        '622446',
        '622447',
        '622448',
        '622449',
        '622450',
        '622451',
        '622452',
        '622453',
        '622454',
        '622455',
        '622456',
        '622457',
        '622458',
        '622459',
        '622460',
        '622461',
        '622462',
        '622463',
        '622464',
        '622465',
        '622466',
        '622467',
        '622468',
        '622469',
        '622470',
        '622471',
        '622472',
        '622473',
        '622474',
        '622475',
        '622476',
        '622477',
        '622478',
        '622479',
        '622480',
        '622481',
        '622482',
        '622483',
        '622484',
        '622485',
        '622486',
        '622487',
        '622488',
        '622489',
        '622490',
        '622491',
        '622492',
        '622493',
        '622494',
        '622495',
        '622496',
        '622497',
        '622498',
        '622499',
        '622500',
        '622501',
        '622502',
        '622503',
        '622504',
        '622505',
        '622506',
        '622507',
        '622508',
        '622509',
        '622510',
        '622511',
        '622512',
        '622513',
        '622514',
        '622515',
        '622516',
        '622517',
        '622518',
        '622519',
        '622520',
        '622521',
        '622522',
        '622523',
        '622524',
        '622525',
        '622526',
        '622527',
        '622528',
        '622529',
        '622530',
        '622531',
        '622532',
        '622533',
        '622534',
        '622535',
        '622536',
        '622537',
        '622538',
        '622539',
        '622540',
        '622541',
        '622542',
        '622543',
        '622544',
        '622545',
        '622546',
        '622547',
        '622548',
        '622549',
        '622550',
        '622551',
        '622552',
        '622553',
        '622554',
        '622555',
        '622556',
        '622557',
        '622558',
        '622559',
        '622560',
        '622561',
        '622562',
        '622563',
        '622564',
        '622565',
        '622566',
        '622567',
        '622568',
        '622569',
        '622570',
        '622571',
        '622572',
        '622573',
        '622574',
        '622575',
        '622576',
        '622577',
        '622578',
        '622579',
        '622580',
        '622581',
        '622582',
        '622583',
        '622584',
        '622585',
        '622586',
        '622587',
        '622588',
        '622589',
        '622590',
        '622591',
        '622592',
        '622593',
        '622594',
        '622595',
        '622596',
        '622597',
        '622598',
        '622599',
        '622600',
        '622601',
        '622602',
        '622603',
        '622604',
        '622605',
        '622606',
        '622607',
        '622608',
        '622609',
        '622610',
        '622611',
        '622612',
        '622613',
        '622614',
        '622615',
        '622616',
        '622617',
        '622618',
        '622619',
        '622620',
        '622621',
        '622622',
        '622623',
        '622624',
        '622625',
        '622626',
        '622627',
        '622628',
        '622629',
        '622630',
        '622631',
        '622632',
        '622633',
        '622634',
        '622635',
        '622636',
        '622637',
        '622638',
        '622639',
        '622640',
        '622641',
        '622642',
        '622643',
        '622644',
        '622645',
        '622646',
        '622647',
        '622648',
        '622649',
        '622650',
        '622651',
        '622652',
        '622653',
        '622654',
        '622655',
        '622656',
        '622657',
        '622658',
        '622659',
        '622660',
        '622661',
        '622662',
        '622663',
        '622664',
        '622665',
        '622666',
        '622667',
        '622668',
        '622669',
        '622670',
        '622671',
        '622672',
        '622673',
        '622674',
        '622675',
        '622676',
        '622677',
        '622678',
        '622679',
        '622680',
        '622681',
        '622682',
        '622683',
        '622684',
        '622685',
        '622686',
        '622687',
        '622688',
        '622689',
        '622690',
        '622691',
        '622692',
        '622693',
        '622694',
        '622695',
        '622696',
        '622697',
        '622698',
        '622699',
        '622700',
        '622701',
        '622702',
        '622703',
        '622704',
        '622705',
        '622706',
        '622707',
        '622708',
        '622709',
        '622710',
        '622711',
        '622712',
        '622713',
        '622714',
        '622715',
        '622716',
        '622717',
        '622718',
        '622719',
        '622720',
        '622721',
        '622722',
        '622723',
        '622724',
        '622725',
        '622726',
        '622727',
        '622728',
        '622729',
        '622730',
        '622731',
        '622732',
        '622733',
        '622734',
        '622735',
        '622736',
        '622737',
        '622738',
        '622739',
        '622740',
        '622741',
        '622742',
        '622743',
        '622744',
        '622745',
        '622746',
        '622747',
        '622748',
        '622749',
        '622750',
        '622751',
        '622752',
        '622753',
        '622754',
        '622755',
        '622756',
        '622757',
        '622758',
        '622759',
        '622760',
        '622761',
        '622762',
        '622763',
        '622764',
        '622765',
        '622766',
        '622767',
        '622768',
        '622769',
        '622770',
        '622771',
        '622772',
        '622773',
        '622774',
        '622775',
        '622776',
        '622777',
        '622778',
        '622779',
        '622780',
        '622781',
        '622782',
        '622783',
        '622784',
        '622785',
        '622786',
        '622787',
        '622788',
        '622789',
        '622790',
        '622791',
        '622792',
        '622793',
        '622794',
        '622795',
        '622796',
        '622797',
        '622798',
        '622799',
        '622800',
        '622801',
        '622802',
        '622803',
        '622804',
        '622805',
        '622806',
        '622807',
        '622808',
        '622809',
        '622810',
        '622811',
        '622812',
        '622813',
        '622814',
        '622815',
        '622816',
        '622817',
        '622818',
        '622819',
        '622820',
        '622821',
        '622822',
        '622823',
        '622824',
        '622825',
        '622826',
        '622827',
        '622828',
        '622829',
        '622830',
        '622831',
        '622832',
        '622833',
        '622834',
        '622835',
        '622836',
        '622837',
        '622838',
        '622839',
        '622840',
        '622841',
        '622842',
        '622843',
        '622844',
        '622845',
        '622846',
        '622847',
        '622848',
        '622849',
        '622850',
        '622851',
        '622852',
        '622853',
        '622854',
        '622855',
        '622856',
        '622857',
        '622858',
        '622859',
        '622860',
        '622861',
        '622862',
        '622863',
        '622864',
        '622865',
        '622866',
        '622867',
        '622868',
        '622869',
        '622870',
        '622871',
        '622872',
        '622873',
        '622874',
        '622875',
        '622876',
        '622877',
        '622878',
        '622879',
        '622880',
        '622881',
        '622882',
        '622883',
        '622884',
        '622885',
        '622886',
        '622887',
        '622888',
        '622889',
        '622890',
        '622891',
        '622892',
        '622893',
        '622894',
        '622895',
        '622896',
        '622897',
        '622898',
        '622899',
        '622900',
        '622901',
        '622902',
        '622903',
        '622904',
        '622905',
        '622906',
        '622907',
        '622908',
        '622909',
        '622910',
        '622911',
        '622912',
        '622913',
        '622914',
        '622915',
        '622916',
        '622917',
        '622918',
        '622919',
        '622920',
        '622921',
        '622922',
        '622923',
        '622924',
        '622925',
      ],
      lengths: [16, 17, 18, 19],
    },
    [PAYMENT_TYPE.MASTER_CARD]: {
      ranges: ['51', '55', '2221', '2223', '2229', '223', '229', '23', '26', '270', '271', '2720'],
      lengths: [16],
    },
    [PAYMENT_TYPE.VISA]: {
      ranges: ['4'],
      lengths: [13, 16, 18, 19],
    },
  };

export const SECONDARY_TRAVELLERS_DEPENDENCY_ROW_TEXT = 'This child is your dependant';

export const INVALID_PROMO_CODE_TITLE = 'Discount code not applied';
export const INVALID_PROMO_CODE_DESCRIPTION =
  'Please use the email associated with this code or remove it to proceed.';
