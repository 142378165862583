import { Amplify } from 'aws-amplify';
import { ApiError } from 'aws-amplify/api';
import get from 'lodash/get';

import type { ClientConfig, clientConfigTypesV1 } from '@aws-amplify/client-config';
import { LibraryOptions } from '@aws-amplify/core';

import type { AwsMobile, AwsWeb } from '../../../../types/src';

type ApiEndpoint = { endpoint: string; region: string; apiName: string };

export const normaliseAmplifyConfiguration = (config: AwsMobile | AwsWeb) => {
  if (get(config, 'version') === '1') {
    return config as any;
  }

  const awsRegion = config.aws_project_region ?? 'ap-southeast-2';

  const newConfig: ClientConfig = {
    version: '1',
    auth: {
      aws_region: config.aws_cognito_region ?? awsRegion,
      user_pool_id: config.aws_user_pools_id,
      user_pool_client_id: config.aws_user_pools_web_client_id,
      identity_pool_id: config.aws_cognito_identity_pool_id,
      unauthenticated_identities_enabled: true,
      oauth: get(config, 'oauth')
        ? {
            identity_providers: get(config, 'oauth.identity_providers', [
              'GOOGLE',
              'SIGN_IN_WITH_APPLE',
            ]),
            scopes: get(config, 'oauth.scope', [
              'phone',
              'email',
              'openid',
              'profile',
              'aws.cognito.signin.user.admin',
            ]),
            redirect_sign_in_uri: [get(config, 'oauth.redirectSignIn', 'freely://ssoRedirect')],
            redirect_sign_out_uri: [get(config, 'oauth.redirectSignOut', 'freely://')],
            domain: get(
              config,
              'oauth.domain',
              'freely-social-oauth-dev.auth.ap-southeast-2.amazoncognito.com',
            ),
            response_type: get(config, 'oauth.responseType', 'code'),
          }
        : undefined,
    },
    data: {
      authorization_types: [
        (config.aws_appsync_authenticationType as clientConfigTypesV1.AwsAppsyncAuthorizationType) ??
          'AWS_LAMBDA',
      ],
      default_authorization_type:
        (config.aws_appsync_authenticationType as clientConfigTypesV1.AwsAppsyncAuthorizationType) ??
        'AWS_LAMBDA',
      url: config.aws_appsync_graphqlEndpoint,
      aws_region: config.aws_appsync_region ?? awsRegion,
    },
    notifications: get(config, 'PushNotification')
      ? {
          channels: get(config, 'PushNotification.channels', ['IN_APP_MESSAGING', 'FCM', 'APNS']),
          aws_region: awsRegion,
          amazon_pinpoint_app_id: get(config, 'PushNotification.appId', ''),
        }
      : undefined,
    analytics: get(config, 'Analytics.AWSPinpoint.appId')
      ? {
          amazon_pinpoint: {
            app_id: get(config, 'Analytics.AWSPinpoint.appId', ''),
            aws_region: get(config, 'Analytics.AWSPinpoint.region', awsRegion),
          },
        }
      : undefined,
    storage: get(config, 'Storage.AWSS3.bucket')
      ? {
          aws_region: get(config, 'Storage.AWSS3.region', awsRegion),
          bucket_name: get(config, 'Storage.AWSS3.bucket', ''),
        }
      : undefined,
    custom: Array.isArray(config.API.endpoints)
      ? {
          API: config.API.endpoints.reduce((prev, { name: apiName, endpoint }) => {
            prev[apiName] = {
              endpoint,
              apiName,
              region: awsRegion,
            };
            return prev;
          }, {} as Record<string, ApiEndpoint>),
        }
      : undefined,
  };

  return newConfig;
};

/**
 * https://docs.amplify.aws/react/build-a-backend/add-aws-services/rest-api/set-up-rest-api/#initialize-amplify-api
 */
export const amplifyConfigure = (config: AwsMobile | AwsWeb, options?: LibraryOptions) => {
  const outputs = normaliseAmplifyConfiguration(config);
  Amplify.configure(outputs);

  const existingConfig = Amplify.getConfig();

  Amplify.configure(
    {
      ...existingConfig,
      API: {
        ...existingConfig.API,
        REST: outputs.custom.API,
      },
    },
    options,
  );
};

export const throwError = ({
  error,
  message = 'API failed',
}: {
  error: unknown;
  message?: string;
}) => {
  if (
    error instanceof ApiError &&
    'response' in error &&
    typeof error.response === 'object' &&
    error.response !== null &&
    'statusCode' in error.response &&
    'body' in error.response
  ) {
    const { statusCode, body } = error.response;
    throw new Error(`${message}: ${statusCode} - ${body}`);
  }
  throw new Error(message);
};

export function extractErrorMessage(input?: string, defaultErrorMessage = 'Api failed') {
  if (!input) {
    return defaultErrorMessage;
  }

  // Define the regular expression to capture the message
  const regex = /"message":"(.*?)"/;
  // Use the regex to find the match in the input string
  const match = input.match(regex);

  return match?.[1] ?? defaultErrorMessage;
}
