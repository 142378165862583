import {
  getBoostSelectedExtraCover,
  getSelectedExtraCoverPrice,
  isBoostExtraCancellation,
} from 'freely-shared-utils';

import { BOOST_CATEGORY, TRIP_TYPE } from '@packages/types';

import { TripStore } from './trip.store';

export function addedSelectedCoverSelector(state: TripStore) {
  const extraCancellationBoost = state?.trip?.boosts?.find(isBoostExtraCancellation);
  const selectedExtraCover = getBoostSelectedExtraCover(extraCancellationBoost);
  return selectedExtraCover ?? null;
}

export function addedSelectedCoverPriceSelector(state: TripStore) {
  const extraCancellationBoost = state?.trip?.boosts?.find(isBoostExtraCancellation);
  return getSelectedExtraCoverPrice(extraCancellationBoost) as number;
}

export const getMyHealthBoostIdSelector = (state: TripStore) => {
  const boost = state?.trip?.boosts?.find?.(it => it.category === BOOST_CATEGORY.MY_HEALTH);

  if (boost) {
    return { boostId: boost.boostId, isAdded: boost.isAdded };
  }
};

export const getDestinationBackgroundImagesSelector = (state: TripStore) => {
  if (state.trip.destinations.length > 0) {
    return state.trip.destinations[0].imageLinks;
  } else {
    return {};
  }
};

export const getSelectedBoostById = (boostId?: string) => (state: TripStore) =>
  state?.trip?.boosts?.find(it => it.boostId === boostId);

export const getFilteredBoostsByCategorySelector =
  (boostCategory: BOOST_CATEGORY) => (state: TripStore) => {
    return state?.trip?.boosts?.filter(({ category }) => {
      return category === boostCategory;
    });
  };

export const selectedBoostsExcludingEMCCountSelector = (state: TripStore) =>
  state?.trip?.boosts?.filter(b => !b.isMandatory && b?.code !== 'EMC').filter(b => b.isAdded)
    .length;

export const allBoostExcludingEMCCountSelector = (state: TripStore) =>
  state?.trip?.boosts?.filter(b => !b.isMandatory && b?.code !== 'EMC').length;

export const getTripSelector = (state: TripStore) => state.trip;
export const tripStartDateSelector = (state: TripStore) => state.trip.startDate;
export const tripEndDateSelector = (state: TripStore) => state.trip.endDate;

export const tripDepositDateSelector = (state: TripStore) => state.trip.depositDate;

export const tripStateSelector = (state: TripStore) => state?.trip?.state;

export const totalTripCostSelector = (state: TripStore) => state?.trip?.totalTripCost;

export const tripTypeSelector = (state: TripStore) =>
  state?.trip?.tripType ?? TRIP_TYPE.INTERNATIONAL;

export const promoCodeSelector = (state: TripStore) => state?.trip?.promotionCode;

export const tripPriceSelector = (state: TripStore) => state?.trip?.price ?? 0;

export const tripExcessAmountSelector = (state: TripStore) =>
  state?.trip?.metaData?.excessAmount ?? 0;

export function promotionCodeLabelSelector(state: TripStore) {
  const promotionCode = state?.trip?.promotionCode;
  return promotionCode?.appLabel === 'default' ? null : promotionCode?.appLabel;
}

export const tripDiscountSelector = (state: TripStore) => state?.trip?.promotionCode?.discountCode;

export const tripSecondaryTravellers = (state: TripStore) => state?.trip?.secondaryTravellers;

export const tripUserIdSelector = (state: TripStore) => state?.trip?.userId ?? '';
